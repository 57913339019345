importera { spinner } från "react-bootstrap".

const Loader = () => {
  returnera (
    <>
      <spinner animation="border" role="status" style="{{" width:="" "100px",="" height:="" margin:="" "auto",="" display:="" "block"}}="">
        <span className="sr-only">Laddar...</span>
      </spinner>
    </>
  )
}

export default Loader
