import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, InputGroup, Row} from 'react-bootstrap'
import {useHistory, useParams} from "react-router-dom";
import {createBlogRequest, getBlogsRequest} from '../redux/actions'
import {AppState, BlogItem} from "../redux/types";
import MDEditor from "@uiw/react-md-editor";
import {LinkContainer} from "react-router-bootstrap";
import replaceSpecialCharacters from "replace-special-characters"
import ReactCrop, {centerCrop, type Crop, makeAspectCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const blogURL = (title: any) => `${replaceSpecialCharacters(title).trim().split(/[\W]+/).join('-')}`;

const BlogForm = () => {
    const ratio = {w: 3, h: 2};
    const cropPreviewWidth = 400; // px
    const myCanvas = useRef(null);
    const cropPreviewHeight = Math.floor(cropPreviewWidth / ratio.w * ratio.h); // px
    const {id} = useParams<{ id: string }>();
    const resources = useSelector((state: AppState) => state.blogs);
    const {list} = resources
    let blog: BlogItem | undefined = undefined;
    try {
        blog = list?.find(o => o.id === parseInt(id));
    } catch (error) {
        console.log(error);
    }
    const [formData, setFormData] = useState({
        id: blog ? parseInt(blog.id) : -1,
        title: blog?.title || '',
        cover: blog?.cover || '',
        cover_type: blog?.cover_type || 'image',
        topics: blog?.topics.map(function (i: any) {
            return i.name
        }).join() || '',
        content_preview: blog?.content_preview || '',
        author_id: undefined,
        featured: blog?.featured || false,
        createdAt: undefined,
        updatedAt: undefined,
        url: blog?.url
    })

    const [content, setContent] = useState(blog?.content || '');
    const [crop, setCrop] = useState<crop>()
    const [naturalImage, setNaturalImage] = useState()

    useEffect(() => {
        //@ts-ignore
        if (blog?.cropWidth && blog.cropX && blog.cropHeight && blog.cropY && naturalImage?.width && naturalImage?.height) {
            setCrop({
                //@ts-ignore
                x: blog?.cropX * (naturalImage.width / naturalImage.naturalWidth),
                //@ts-ignore
                y: blog?.cropY * (naturalImage.height / naturalImage.naturalHeight),
                //@ts-ignore
                width: blog?.cropWidth * (naturalImage.width / naturalImage.naturalWidth),
                //@ts-ignore
                height: blog?.cropHeight * (naturalImage.height / naturalImage.naturalHeight),
                unit: 'px'
            })
        }
    }, [blog, naturalImage]);

    const dispatch = useDispatch()
    const history = useHistory()

    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getBlogsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        let {value, name} = e.target;
        if (name === 'url') {
            value = blogURL(value)
        }
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            }
        })

        if (name === 'title') {
            setFormData((prevValue: any) => {
                return {
                    ...prevValue,
                    url: blogURL(value),
                }
            })
        }
    }

    const handleCheck = (e: React.ChangeEvent<htmlinputelement>) => {
        const {checked, name} = e.target
        console.log(name)
        console.log(checked)
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: checked,
            }
        })
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        // @ts-ignore
        dispatch(createBlogRequest({
            id: formData.id === -1 ? undefined : formData.id,
            title: formData.title,
            cover: formData.cover,
            cover_type: formData.cover_type,
            topics: formData.topics,
            content_preview: formData.content_preview,
            content: content,
            author_id: formData.author_id,
            featured: formData.featured,
            createdAt: formData.createdAt,
            updatedAt: formData.updatedAt,
            url: formData.url,
            // @ts-ignore
            cropX: Math.floor(crop?.x * (naturalImage.naturalWidth / naturalImage.width)),
            // @ts-ignore
            cropY: Math.floor(crop?.y * (naturalImage.naturalHeight / naturalImage.height)),
            // @ts-ignore
            cropWidth: Math.floor(crop?.width * (naturalImage.naturalWidth / naturalImage.width)),
            // @ts-ignore
            cropHeight: Math.floor(crop?.height * (naturalImage.naturalHeight / naturalImage.height))
        }))
        history.push('/admin/blogs')
        dispatch(getBlogsRequest())
    }

    const [editURL, setEditURL] = useState(false);
    const editURLHandler = () => {
        setEditURL(current => !current)
    }

    const generateURLHandler = () => {
        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                url: blogURL(prevValue?.title),
            }
        })
    }

    function onImageLoad(e: any) {
        const {naturalWidth: width, naturalHeight: height} = e.currentTarget
        setNaturalImage(e.currentTarget)
        if (!blog?.cropY) {
            const crop = centerCrop(
                makeAspectCrop(
                    {
                        // You don't need to pass a complete crop into
                        // makeAspectCrop or centerCrop.
                        unit: '%',
                        width: 100,
                    },
                    ratio.w / ratio.h,
                    width,
                    height
                ),
                width,
                height
            )
            setCrop(crop)
        }
    }

    useEffect(() => {
        const canvasRender = () => {
            //@ts-ignore
            const ctx = myCanvas.current?.getContext('2d')
            if (!ctx) {
                throw new Error('No 2d context')
            }
            // const pixelRatio = window.devicePixelRatio
            // ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'
            // @ts-ignore
            const cropX = crop.x * (cropPreviewWidth / crop.width)
            // @ts-ignore
            const cropY = crop.y * (cropPreviewHeight / crop.height)
            ctx.save()
            ctx.translate(-cropX, -cropY)
            //@ts-ignore
            ctx.scale(naturalImage.width / crop.width, naturalImage.height / crop.height)
            ctx.drawImage(
                naturalImage,
                0,
                0,
                //@ts-ignore
                naturalImage?.naturalWidth,
                //@ts-ignore
                naturalImage?.naturalHeight,
                0,
                0,
                //@ts-ignore
                cropPreviewWidth,
                //@ts-ignore
                cropPreviewHeight,
            )

            ctx.restore()
        }
        //@ts-ignore
        if (crop?.x && crop?.y && crop?.width && crop?.height && naturalImage?.width && naturalImage?.height) {
            canvasRender();
        }
    }, [crop?.x, crop?.y, crop?.width, crop?.height, cropPreviewHeight, cropPreviewWidth, naturalImage]);

    // @ts-ignore
    return (
        <container className="register-form-container" data-color-mode="light">
            <form onSubmit="{submitHandler}" className="service-form">
                <form.group controlId="blog-title">
                    <form.label>Titel</form.label>
                    <form.control placeholder="Text" name="title" value="{formData.title}" onChange="{handleChange}" readOnly="{user.readOnly}"></form.control>
                </form.group>
                <form.group controlId="blog-url">
                    <form.label>URL</form.label>
                    <inputgroup>
                        <form.control type="text" name="url" placeholder="Autogenerated URL" value="{formData.url}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !editURL}=""></form.control>
                        <button variant="outline-secondary shadow-none show-refresh-btn" onClick="{generateURLHandler}" disabled="{user.readOnly}">
                            <i className="fas fa-sync-alt"></i>
                        </button>
                        <button variant="outline-secondary shadow-none show-password-btn" onClick="{editURLHandler}" disabled="{user.readOnly}">
                            <i className="{editURL" ?="" 'fas="" fa-lock'="" :="" fa-unlock-alt'}=""></i>
                        </button>
                    </inputgroup>
                    <div style="{{" padding:="" '8px="" 0',="" color:="" '#666555',="" fontSize:="" '0.8rem',="" }}="">
                        {formData.url ?
                            `${window.location.origin}/blog/${formData.url}` :
                            `${window.location.origin}/blog/${formData.id}`
                        }
                    </div>
                </form.group>
                <form.group controlId="blog-featured">
                    <form.check label="Featured" type="checkbox" name="featured" checked="{formData.featured}" onChange="{handleCheck}" disabled="{user.readOnly}"></form.check>
                </form.group>
                <form.group controlId="blog-cover_type">
                    <form.label>Typ av skydd</form.label>
                    <form.control as="select" name="cover_type" aria-label="Image" value="{formData.cover_type}" onChange="{handleChange}">
                        <option id="image" value="image">
                            Bild
                        </option>
                        <option id="video" value="video">
                            Video
                        </option>
                        <option id="youtube_video" value="youtube_video">
                            YouTube-video
                        </option>
                    </form.control>
                </form.group>
                <form.group controlId="blog-cover">
                    <form.label>Skydd</form.label>
                    <form.control placeholder="Cover" name="cover" value="{formData.cover}" onChange="{handleChange}" readOnly="{user.readOnly}"></form.control>
                    {formData.cover_type === 'video' && <small className="for-example">
                        Klistra in hela länken: <span style="{{color:" 'lightgrey'}}="">https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/C0009.MP4</span>
                    </small>}
                    {formData.cover_type === 'youtube_video' && <small className="for-example">
                        Infoga YouTube-video ID: <span style="{{color:" 'lightgrey'}}="">foOL310imec</span>
                    </small>}
                </form.group>
                {formData.cover_type === 'image' && <div>
                    <reactcrop crop="{crop}" onChange="{crop" ==""> {
                            setCrop(crop);
                        }}
                        locked={false}
                        aspect={ratio.w / ratio.h}
                        keepSelection={true}
                    >
                        <img src="{formData.cover}" alt="beskär" onLoad="{onImageLoad}">
                    </reactcrop>
                </div>}
                {formData.cover_type === 'youtube_video' && <div>
                    <reactcrop crop="{crop}" onChange="{c" ==""> {
                            setCrop(c)
                        }}
                        locked={false}
                        aspect={förhållande.w / förhållande.h}
                        keepSelection={troligt}
                    >
                        <img src="{`https://img.youtube.com/vi/${formData.cover}/maxresdefault.jpg`}" alt="beskär" onLoad="{onImageLoad}">
                    </reactcrop>
                </div>}
                {(formData.cover_type === 'image' || formData.cover_type === 'youtube_video') && <canvas ref="{myCanvas}" width="{cropPreviewWidth}" height="{cropPreviewHeight}" style="{{" display:="" "none"="" }}=""></canvas>
                }
                <form.group controlId="blog-end">
                    <form.label>Ämnen (använd <code>,</code> som separator)</form.label>
                    <form.control placeholder="{&quot;Topics&quot;}" name="topics" value="{formData.topics}" onChange="{handleChange}" readOnly="{user.readOnly}"></form.control>
                </form.group>
                <form.group controlId="blog-cover">
                    <form.label>Förhandsgranskning av innehåll</form.label>
                    <form.control placeholder="Content preview" name="content_preview" value="{formData.content_preview}" onChange="{handleChange}" readOnly="{user.readOnly}"></form.control>
                </form.group>
                {user.readOnly ? <mdeditor.markdown source="{content}"></mdeditor.markdown> : <mdeditor value="{content}" @ts-ignore="" onChange="{setContent}"></mdeditor>
                }
                <row>
                    <col>
                        <linkcontainer to="{`/admin/blogs`}">
                            <button className="tallenna paymentmethod-back-button">
                                Tillbaka
                            </button>
                        </linkcontainer>
                    
                    {!user.readOnly &&
                        <col style="{{" textAlign:="" 'right',="" }}="">
                            <button className="create-service-button tallenna" type="submit" disabled="{!formData?.title}">
                                Spara
                            </button>
                        
                    }
                </row>
            </form>
        </container>
    )
}

export standard BlogForm
</htmlinputelement></htmlinputelement></crop>