import {Button, Container} from 'react-bootstrap'
import React from "react";

const Intro = ({onSelect}: any) => {
    const handleClick = () => {
        onSelect('asiakas')
    }

    return (
        <container className="introduction">
            <h1 className="introduction-header">Fyll i kontaktuppgifter till ditt kundkonto</h1>
            <h4 className="welcome-greeting">
                Tack för din beställning och välkommen till Digihappy!
            </h4>
            <p className="instructions">
                Genom att fylla i dina egna, din äldres och dina anhörigas kontaktuppgifter kan du få tillgång till tjänsten.
            </p>
            <p style="{{textAlign:" 'right'}}="">
                <button onClick="{handleClick}" className="tallenna">
                    Nästa
                </button>
            </p>
            <p>
                Observera! Vi kommer att skicka dig två e-postmeddelanden om din beställning och ditt konto. Om du använder en Gmail-adress,
                kontrollera även mappen "Kampanjer".
            </p>
        </container>
    )
}

export standard Intro
