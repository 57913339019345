import Carousel from "react-bootstrap/Carousel";
import styles from "./carousel.module.css";

export type CarouselItem = {
    title: string;
    content: string;
    author: string;
};

function HomeCarousel({ items }: { items: CarouselItem[] }) {
    return (
        <carousel indicators="{false}" interval="{6000}" className="{styles.container}">
            {items.map((item, nyckel) => (
                <carousel.item key="{key}">
                    <div className="{styles.item}">
                        <h3 className="{styles.title}">{item.title}</h3>
                        <h4 className="{styles.content}">{objekt.innehåll}</h4>
                        <p className="{styles.author}">{artikel.författare}</p>
                    </div>
                </carousel.item>
            ))}
        </carousel>
    );
}

export default HomeCarousel;
