import { HashLink as Link } from "react-router-hash-link";

const youtubeLink = "https://www.youtube.com/watch?v=8GAQYE12YXQ";
const ServicesInfo2 = () => {
    return (
        <>
            <div className="global-box-line-container">
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Surfplatta, abonnemang, stativ</div>
                        <ul className="services-info-text">
                            <li>Samsung Galaxy Tab (två storlekar) eller Apple iPad </li>
                            <li>Abonnemang med obegränsad data </li>
                            <li>
                                Piedestal -{""}
                                <a href="{youtubeLink}" className="lue-lisää-linkki" target="_blank" rel="noreferrer">
                                    <strong>se video</strong>
                                </a>
                            </li>
                        </ul>
                        <p className="options">
                            <link 500="" className="linkki global-text-dark" to="/palvelut#palvelut" style="{{" fontWeight:="" }}="">
                                Beroende på din seniors förmåga kan du välja en surfplatta från kategorierna Trygg, Enkel eller Mästare.
                            
                        </p>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/tabletti-senorille.webp" alt="Personen håller en surfplatta i sin hand." className="global-image">
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-image-container">
                        <img src="/assets/images/services/senioritabletti.webp" alt="Personen använder en surfplatta och en pekskärmspenna." className="global-image">
                    </div>
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Individuellt skräddarsydd surfplatta och Digihappys hemskärm</div>
                        <ul className="services-info-text">
                            <li>Inställningar, appar och kontakter redo för seniorvänlig användning </li>
                            <li>För seniorer som inte själva rör vid surfplattan kan du bara välja videosamtalsvyn</li>
                            <li>
                                För aktiva seniorer, även radioapparater, ljudböcker, tidningar,{""}
                               <a href="https://areena.yle.fi/" className="linkki" target="_blank" rel="noreferrer">
                                    Yle Arena
                               </a>
                                spel eller e-post - och möjlighet att lägga till egna appar
                            </li>
                            <a href="{youtubeLink}" className="lue-lisää-linkki" target="_blank" rel="noreferrer">
                                <strong>Titta på videon</strong>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Enkla och säkra videosamtal</div>

                        <ul className="services-info-text">
                            <li>Digihappys videoapp finns på seniorens surfplatta och en närståendes mobiltelefon</li>
                            <li>När en anhörig ringer öppnas seniorens video utan beröring om så önskas</li>
                            <li>Senioren ringer genom att trycka på bilden av den närstående</li>
                            <li>Säkert, endast familjekontots medlemmar kan ringa</li>
                        </ul>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/hyvä-senioritabletti.webp" alt="En kvinna använder en surfplatta medan hon ligger i sängen" className="global-image">
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-image-container">
                        <img src="/assets/images/services/4.png" alt="H" className="global-image">
                    </div>
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">Familjekonto för videosamtal</div>
                        <ul className="services-info-text">
                            <li>Du kan ansluta sex familjemedlemmar eller vänner till tjänsten. En medlem kan ringa ett videosamtal, som automatiskt öppnas på seniorens surfplatta om så önskas. Medlemmens bild visas i seniorens videosamtalsvy.</li>
                            <li>Medlemmen har tillgång till IT-stöd.</li>
                        </ul>
                    </div>
                </div>
                <div className="global-box-line">
                    <div className="global-box global-background-gray">
                        <div className="services-info-title">IT-support, fjärrhantering, säkerhet, uppdateringar</div>
                        <ul className="services-info-text">
                            <li>Råd vid ibruktagande</li>
                            <li>Kontinuerlig IT-support via telefon eller meddelanden</li>
                            <li>Fjärrhantering av din surfplatta, även utan aktivt deltagande av den äldre personen </li>
                            <li>Datasäkerhet och uppdateringar</li>
                        </ul>
                    </div>
                    <div className="global-image-container">
                        <img src="/assets/images/services/ittuki-seniorille.webp" alt="Två kvinnor sitter i en soffa och fokuserar på en surfplatta. De ringer ett samtal till en IT-stödtjänsten." className="global-image">
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesInfo2;
