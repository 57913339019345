import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Banner from "../components/layout/banner/Banner";
import { useSelector } from "react-redux";
import { AppState } from "../redux/types";

const styles = {
    customBody: {
        border: "none",
    },
};

const ForCompanies = () => {
    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;
    const currentDate = Date.now();
    return (
        <>
            <helmet>
                <title>Säkerhetsarmband för vårdtjänster, surfplattor, videosamtal, meddelanden </title>
                <meta name="description" content="Innovativa tjänster för surfplattor och säkerhetsarmband för vårdtjänster. Videosamtal. Meddelanden. Nya aktiviteter. Loggning.">
            </helmet>
            <banner 56="" backgroundImage="/assets/images/banners/company.webp" height="{`calc(100vh" -="" ${="" +="" *="" list?.filter((el)=""> {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title">För distansvård, boendeservice, kommuner och organisationer</h1>
                    <p className="global-banner-text">Och du får mer från Digihappy på samma gång!</p>
                </div>
            </banner>

            <container fluid="" className="my-5 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <div>
                    <h2 className="global-section-title global-primary">Surfplattetjänst för hemsjukvård - Distansvård</h2>
                    <p className="global-text global-text-normal global-text-dark">Effektiv distansvård till låg kostnad. Videosamtalstjänst med vårdare och familj. Dessutom intressanta aktiviteter för seniorer.</p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Surfplattetjänst för kommuner och organisationer - Stöd de mest utsatta</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Stöd en äldre eller funktionshindrad persons förmåga att fungera och umgås. Lättar bördan för vårdgivaren. Enkla videosamtal med familjen, vårdgivaren och volontären. Utöver det: aktiverande sysselsättning.
                    </p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Vårdarsurfplatta - Nya aktiviteter och loggning</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Uppskatta sköterskan med de bästa verktygen. För nya aktiviteter och enkel inspelning. Hopp, musik, spel, aktivitetshantering. Medicinskt validerade hälsotester och frågeformulär. Utbildning,
                        medicinsk konsultation och vägledning samt IT-stöd. Allt redo att användas.
                    </p>
                </div>
                <div>
                    <h2 className="global-section-title global-primary">Trygghetslarm - Positionering, röstkommunikation och larm</h2>
                    <p className="global-text global-text-normal global-text-dark">
                        Sveriges mest populära platsbaserade säkerhetsarmband från Posifon Digihappy. Tal och exakt gps-positionering överallt. Lätt att använda som trygghetsarmband eller halsband. Automatiskt larm när senioren lämnar området, ramlar omkull eller när batteriet är svagt.
                        {""}
                        <link to="#" className="global-text">
                            Läs mer om detta.
                        
                    </p>
                </div>
            </container>

            <h2 className="global-section-title global-primary global-text-strong text-center mt-6 mb-5">Varför Digihappy?</h2>

            <section className="image-rows">
                <div className="global-grid-container-2">
                    <card className="home-image-card">
                        <card.img src="{&quot;/assets/images/company/1.webp&quot;}" className="global-card-image"></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                Enkla videosamtal. Svara om så önskas utan beröring, ring genom att trycka på en bild.
                            </card.title>
                        </card.body>
                    </card>
                    <card className="home-image-card">
                        <card.img src="{&quot;/assets/images/company/2.webp&quot;}" className="global-card-image"></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                Trevliga saker att göra. Förinstallerad med de appar du vill ha, de appar du har testat och de bästa inställningarna. Ljudböcker, radio, YleAreena, nyheter och varför inte patiens.
                            </card.title>
                        </card.body>
                    </card>
                    <card className="home-image-card">
                        <card.img src="/assets/images/company/3.jpg" className="global-card-image"></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                "Modern centralradio", som gör det möjligt för vårdgivaren att kommunicera samtidigt med alla boende via video- och ljudmeddelanden. Enkelt från din egen mobiltelefon.
                            </card.title>
                        </card.body>
                    </card>
                    <card className="home-image-card">
                        <card.img src="/assets/images/company/hoitaja-käyttää-senioritabletti.webp" width="auto" className="global-card-image" alt="Vihreään paitaan pukeutunut nainen pitelee tablettia."></card.img>

                        <card.body className="company-body" style="{styles.customBody}">
                            <card.title as="div" className="image-text">
                                Förenkla vårdgivarens jobb. Nya aktiviteter och enkel loggning. Allt är redo för användning.
                            </card.title>
                        </card.body>
                    </card>
                </div>
                <container fluid="" className="my-5 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)",="" textAlign:="" "center"="" }}="">
                    <link className="ota-yhteyttä-linkki text-center" to="/yhteydenotto">
                        Kontakta oss
                    
                    <p className="global-text global-text-normal text-center my-3">Kostnaden för surfplattetjänsten från 40 € (moms 0) / månad. Samsung-surfplatta, stativ, abonnemang, videosamtalstjänst, appar, it-support och fjärrhantering.</p>
                </container>
            </section>
        </>
    );
};

export default ForCompanies;
