import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";
import Banner from "../../components/layout/banner/Banner";
import { ServicesType } from "../phone/Phone";
import { classNames } from "../../utils/classnames";
import styles from "./watch.module.css";

const Watch = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const services: ServicesType[] = [
        {
            content: (
                <>
                    <div className="services-info-title">Posifons positionerande trygghetslarm</div>
                    <ul className="services-info-text">
                        <li>Gps 4G-positionering, vattentät IP67</li>
                        <li>Trygghetslarm, lättvikt 23 g, silikon</li>
                        <li>Halsbandsmodell lätt 21 g</li>
                        <li>Valfria tillägg enligt önskemål</li>
                        <li>
                            <a href="https://www.posifon.care/" className="global-link" target="_blank" rel="noreferrer">
                                Sveriges mest populära </a>
                            {" "}
                            lokalisering av säkerhetslarm
                        </li>
                        <li>Digihappy köper tillbaka en enhet i gott skick upptill ett år efter ibruktagandet</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/seniori-turvaranneke.png",
            alt: "Musta turvaranneke seniorin ranteessa",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Tillförlitliga trygghetslarm</div>
                    <ul className="services-info-text">
                        <li>En äldre person larmar hjälp med en knapptryckning</li>
                        <li>Larmet går till de närståendes mobiltelefoner</li>
                        <li>Samtalskontakten öppnas omedelbart och positionering</li>
                        <li>Automatiska varningar när senioren lämnar området, faller eller får slut på batteri</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/turvaranneke-seniorille-kaulamalli.webp",
            alt: "Käsi pitelee kaulamalli-turvahälytintä",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Abonnemang</div>
                    <ul className="services-info-text">
                        <li>Abonnemanget är via Digihappy och klar på enheten.</li>
                        <li>Larm, positionering och samtalsförbindelse fungerar överallt tack vare anslutningen.</li>
                        <li>Enhetens funktion är INTE kopplad till den äldre personens mobiltelefon.</li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/paras-senioriturvaranneke.jpg",
            alt: "Valkoinen kissa nauttii vanhemman henkilön silittelystä, henkilöllä on turvahälytin ranteessaan",
            credit: "BILD: : Björn Johansson/Posifon",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Posifon Care-app för anhöriga</div>
                    <ul className="services-info-text">
                        <li>En närstående använder den enkla Posifo-appen på sin mobiltelefon</li>
                        <li>
                            Notiser och larm skickas till de närståendes mobiltelefoner
                            <ul>
                                <li>Beställning, mottagare och tid kan anpassas i stor utsträckning</li>
                            </ul>
                        </li>
                    </ul>
                </>
            ),
            image: "/assets/images/watch/helppo-senioripuhelin.webp",
            alt: "Mies, jolla on silmälasit ja parta, katsoo puhelintaan.",
        },
    ];

    const [play, setPlay] = useState(0);
    return (
        <>
            <helmet>
                <title>Säkerhetsarmband för äldre I Gps-spårning, larm. IT-support</title>
                <meta name="description" content="En fasthållningsanordning är det bästa skyddet för äldre personer. Enkelt larm och röstkommunikation. Installation och it-support.">
            </helmet>
            <banner 56="" backgroundImage="/assets/images/banners/turvaranneke-senorille.jpg" label="Iäkäs nainen vilkaisee rannekelloaan ollessaan ulkona puistossa" height="{`calc(100vh" -="" ${="" +="" *="" list?.filter((el)=""> {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="{classNames(&quot;global-banner-title" global-white",="" styles.bannerHeading)}="">Trygghetslarm</h1>
                    <p className="global-banner-text global-white">Larm, samtalsförbindelse och positionering med en enkel knapptryckning.</p>
                    <link to="/osta/turvaranneke" style="{{" marginTop:="" "auto"="" }}="">
                        <button secondary="" emphasized="">
                            KÖP ETT TRYGGHETSLARM
                        </button>
                    
                </div>
            </banner>
            <container fluid="" className="my-3 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <h1 className="services-header">Trygghetslarm och Digihappy-tjänst - Vi gör allt åt dig</h1>
                <div className="global-box-line-container">
                    {services.map((tjänst, nyckel) => (
                        <div className="global-box-line" key="{key}">
                            {key % 2 === 0 ? (
                                <react.fragment>
                                    <div className="global-box global-background-gray">{tjänst.innehåll}</div>
                                    <div className="{classNames(&quot;global-image-container&quot;," "global-credit-image-container")}="">
                                        <img src="{service.image}" alt="{service.alt}" className="global-image">
                                        {tjänst.kredit && <span className="global-credit-text-right">{service.kredit}</span>}
                                    </div>
                                </react.fragment>
                            ) : (
                                <react.fragment>
                                    <div className="{classNames(&quot;global-image-container&quot;," "global-credit-image-container")}="">
                                        <img src="{service.image}" alt="H" className="global-image" about="{service.alt}">
                                        {tjänst.kredit && <span className="global-credit-text">{service.kredit}</span>}
                                    </div>
                                    <div className="global-box global-background-gray">{tjänst.innehåll}</div>
                                </react.fragment>
                            )}
                        </div>
                    ))}
                </div>
            </container>
            <container fluid="" className="my-3 services-container service-table-container" id="palvelut">
                <div className="services-button-container">
                    <button onClick="{()" ==""> setPlay(1)}>Visa video</button>
                    <div className="global-video-wrapper">
                        <iframe 16="" src="{`https://www.youtube.com/embed/PFzWQqfrVac?autoplay=${play}`}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen="" title="Digihappy Seniorsurfplattor" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" height:="" border:="" "none",="" aspectRatio:="" 9,="" }}=""></iframe>
                    </div>
                </div>
            </container>
        </>
    );
};

export default Watch;
