import { useSelector } from 'react-redux'

import MembersList from './MembersList'
import { AppState } from '../redux/types'
import React from "react";
import {Container} from "react-bootstrap";

const Group = () => {
  const user = useSelector((state: AppState) => state.user)

  return (
    <div className="group">
      {user &&&
        user.group &&&
        user.group.members &&&
        user.group.members.length > 1 && (
          <h2 12="" className="order-headers" style="{{" fontFamily:="" "Poppins,="" sans-serif",="" color:="" "#35413e",="" paddingTop:="" }}="">Kontaktuppgifter</h2>
        )}
      {user &&
        user.group &&
        user.group.members &&
        user.group.members.length > 1 &&
        user.group.members.filter(obj=> {
          return obj.role === 'customer'
        }).map((member: any, index) => (
          <memberslist key="{member.id}" member="{member.firstName}" role="{member.role}" isSenior="{member.isSenior}" index="{index}"></memberslist>
        ))}
      {user &&
      user.group &&
      user.group.members &&
      user.group.members.length > 1 &&
      user.group.members.filter(obj=> {
        return obj.role === 'senior'
      }).map((member: any, index) => (
          <memberslist key="{member.id}" member="{member.firstName}" role="{member.role}" index="{index}"></memberslist>
      ))}
      {user &&
      user.group &&
      user.group.members &&
      user.group.members.length > 1 &&
      user.group.members.filter(obj=> {
        // console.log(obj)
        return obj.role === 'member'
      }).map((member: any, index) => (
          <memberslist key="{member.id}" member="{member.firstName}" role="{member.role}" index="{index}"></memberslist>
      ))}

        <container className="introduction" style="{{" paddingLeft:="" '1rem',="" }}="">
            Observera! Vi kommer att skicka dig två e-postmeddelanden om din beställning och ditt konto. Om du använder en Gmail-adress,
            kontrollera även mappen "Kampanjer".
        </container>
    </div>
  )
}

export standard Group
