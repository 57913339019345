import { Redirect, Route, Switch } from "react-router-dom";

import Home from "./screens/home/Home";
import Services from "./screens/Services";
// import ServiceScreen from './screens/ServiceScreen'
import Login from "./screens/Login";
import UserPage from "./screens/UserPage";
import Cart from "./screens/Cart";
import Contact from "./screens/Contact";
import ForCompanies from "./screens/ForCompanies";
import ServiceForm from "./components/ServiceForm";
import Register from "./screens/Register";
import RecoverPassword from "./screens/RecoverPassword";
import UpdatePassword from "./screens/UpdatePassword";
import Orders from "./screens/Orders";
import Users from "./screens/Users";
import Health from "./screens/Health";
import Video from "./screens/Video";
import Shipping from "./screens/Shipping";
import PaymentMethod from "./screens/PaymentMethod";
import PlaceOrder from "./screens/PlaceOrder";
import ContactInfo from "./screens/ContactInfo";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import { clearUserError } from "./redux/actions";
import { useDispatch } from "react-redux";
import ServicesList from "./screens/ServicesList";
import TermsOfUse from "./screens/TermsOfUse";
import AlertsList from "./screens/AlertsList";
import AlertForm from "./components/AlertForm";
import CouponsList from "./screens/CouponsList";
import CouponForm from "./components/CouponForm";
import BlogForm from "./components/BlogForm";
import BlogsList from "./screens/BlogsList";
import Article from "./screens/Article";
import UserEditForm from "./components/UserEditForm";
import AboutUs from "./screens/AboutUs";
import SeniorHomesList from "./screens/SeniorHomesList";
import SeniorHomeForm from "./components/SeniorHomeForm";
import Nurse from "./screens/Nurse";
import NurseSeniors from "./screens/NurseSeniors";
import NurseEditForm from "./components/NurseEditForm";
import NurseForm from "./components/NurseForm";
import RegisterNurseForm from "./components/RegisterNurseForm";
import ITSupport from "./screens/ITSupport";
import ITSupportForm from "./components/ITSupportForm";
import RegisterItSupport from "./components/RegisterItSupport";
import ITSupportEditForm from "./components/ITSupportEditForm";
import Instructions from "./screens/Instructions";
import FAQPage from "./screens/FAQ";
import VideoCallRoom from "./screens/VideoCallRoom";
import Osta from "./screens/osta/Osta";
import Phone from "./screens/phone/Phone";
import Watch from "./screens/watch/Watch";
import ProductsScreen from "./screens/products/Products";
import Thanks from "./screens/thanks/Thanks";

const Routes = () => {
    const dispatch = useDispatch();
    dispatch(clearUserError());

    return (
        <main style="{{" minHeight:="" `calc(100vh="" -="" 226px)`,="" }}="">
            <switch>
                <route path="/" component="{Home}" exact=""></route>
                <route path="/osta" component="{Osta}" exact=""></route>
                <route path="/senioritabletti" component="{Services}" exact=""></route>
                <route path="/senioripuhelin" component="{Phone}" exact=""></route>
                <route path="/turvaranneke" component="{Watch}" exact=""></route>
                <route path="/thanks" component="{Thanks}" exact=""></route>
                <route path="/osta/:cat" component="{ProductsScreen}" exact=""></route>
                <route path="/copyright" component="{PrivacyPolicy}" exact=""></route>
                <route path="/palveluehdot" component="{TermsOfUse}" exact=""></route>
                <route path="/kirjaudu" component="{Login}"></route>
                <route path="/tili/:tab_id" component="{UserPage}"></route>
                <route path="/user">
                    <redirect to="/tili"></redirect>
                </route>
                <route path="/tili">
                    <redirect to="/tili/tilaus"></redirect>
                </route>
                <route path="/ostoskori" component="{Cart}"></route>
                <route path="/toimitus" component="{Shipping}"></route>
                <route path="/maksutapa" component="{PaymentMethod}"></route>
                <route path="/tee_tilaus" component="{PlaceOrder}"></route>
                <route path="/yhteydenotto" component="{Contact}"></route>
                <route path="/contact_info" component="{ContactInfo}"></route>
                <route path="/yrityksille" component="{ForCompanies}"></route>
                <route path="/usein_kysyttya" component="{FAQPage}"></route>
                <route path="/admin/users/:id" component="{UserEditForm}"></route>
                <route path="/admin/users" component="{Users}"></route>
                <route path="/admin/orders" component="{Orders}"></route>
                <route path="/admin/service/:id" component="{ServiceForm}"></route>
                <route path="/admin/services" component="{ServicesList}"></route>
                <route path="/admin/alert/:id" component="{AlertForm}"></route>
                <route path="/admin/alerts" component="{AlertsList}"></route>
                <route path="/admin/coupon/:id" component="{CouponForm}"></route>
                <route path="/admin/coupons" component="{CouponsList}"></route>
                <route path="/admin/blog/:id" component="{BlogForm}"></route>
                <route path="/admin/blogs" component="{BlogsList}"></route>
                <route path="/luo_tili" component="{Register}"></route>
                <route path="/salasana/vaihda" component="{RecoverPassword}"></route>
                <route path="/happysenior_blogi" component="{Health}"></route>
                <route path="/instructions" component="{Instructions}"></route>
                <route path="/blog/:id" component="{Article}"></route>
                <route path="/video" component="{Video}"></route>
                <route path="/password/reset/:userId/:token" component="{UpdatePassword}"></route>
                <route path="/aboutus" component="{AboutUs}"></route>
                <route path="/admin/senior_home/:id" component="{SeniorHomeForm}"></route>
                <route path="/admin/senior_homes" component="{SeniorHomesList}"></route>
                <route path="/admin/nurse" exact="" component="{Nurse}"></route>
                <route path="/admin/nurses/add" exact="" component="{NurseForm}"></route>
                <route path="/admin/nurses/:id" exact="" component="{NurseEditForm}"></route>
                <route path="/admin/nurses/seniors/:id" exact="" component="{NurseSeniors}"></route>
                <route path="/invite/staff/nurse" component="{RegisterNurseForm}"></route>
                <route path="/admin/it_support" exact="" component="{ITSupport}"></route>
                <route path="/admin/it_support/add" exact="" component="{ITSupportForm}"></route>
                <route path="/invite/staff/it_support" component="{RegisterItSupport}"></route>
                <route path="/admin/it_support/:id" exact="" component="{ITSupportEditForm}"></route>
                <route path="/meet/:token/:id" exact="" component="{VideoCallRoom}"></route>

                <route path="/services">
                    <redirect to="/palvelut"></redirect>
                </route>
                <route path="/subscribe">
                    <redirect to="/tilaa"></redirect>
                </route>
                <route path="/shoppingcart">
                    <redirect to="/ostoskori"></redirect>
                </route>
                <route path="/shipping">
                    <redirect to="/toimitus"></redirect>
                </route>
                <route path="/paymentmethod">
                    <redirect to="/maksutapa"></redirect>
                </route>
                <route path="/placeorder">
                    <redirect to="/tee_tilaus"></redirect>
                </route>
                <route path="/terms">
                    <redirect to="/palveluehdot"></redirect>
                </route>
                <route path="/login">
                    <redirect to="/kirjaudu"></redirect>
                </route>
                <route path="/contact">
                    <redirect to="/yhteydenotto"></redirect>
                </route>
                <route path="/companies">
                    <redirect to="/yrityksille"></redirect>
                </route>
                <route path="/register">
                    <redirect to="/luo_tili"></redirect>
                </route>
                <route path="/password/recover">
                    <redirect to="/salasana/vaihda"></redirect>
                </route>
                <route path="/health">
                    <redirect to="/happysenior_blogi"></redirect>
                </route>
            </switch>
        </main>
    );
};

export default Routes;
