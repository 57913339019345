const StepsHeader = () => {
    returnera (
        <>
                <div className="steps-notification">
                    <div>
                        Problem?
                       <br/>
                        Ring
                       <br/>
                       <a className="linkki" href="tel:010 517 2070">010 517 2070</a>
                       <br/>
                        eller whatsapp
                       <br/>
                       <a className="linkki" href="https://wa.me/358503210100" target="_blank">050 321 0100</a>
                    </div>
                </div>
        </>
    )
}

export default StepsHeader
