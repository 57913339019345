import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import Logo from "./Logo";
import { AppState } from "../redux/types";
import LocalStorage from "../local-storage";
import {
  logoutUser,
  ResetBlog,
  ResetCoupons,
  ResetOrders,
  ResetUsers,
} from "../redux/actions";
import { ResetShoppingcart } from "../redux/actions";

const NavBar = () => {
  const user = useSelector((state: AppState) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    LocalStorage.removeToken();
    history.push("/");
    dispatch(logoutUser());
    dispatch(ResetShoppingcart());
    dispatch(ResetUsers());
    dispatch(ResetBlog());
    dispatch(ResetCoupons());
    dispatch(ResetOrders());
  };

  return (
    <navbar collapseOnSelect="" expand="xl" bg="light" variant="light" className="custom-nav">
      <linkcontainer to="/">
        <navbar.brand className="logo">
          <logo></logo>
        </navbar.brand>
      </linkcontainer>
      <navbar.toggle aria-controls="responsive-navbar-nav" className="hamburger"></navbar.toggle>
      <navbar.collapse id="responsive-navbar-nav">
        <nav className="mr-auto">
          <linkcontainer to="/osta">
            <nav.link>Köp</nav.link>
          </linkcontainer>
          <linkcontainer to="/senioritabletti">
            <nav.link>Seniorsurfplatta</nav.link>
          </linkcontainer>
          <linkcontainer to="/senioripuhelin">
            <nav.link>Telefon för seniorer</nav.link>
          </linkcontainer>
          <linkcontainer to="/turvaranneke">
            <nav.link>Trygghetslarm</nav.link>
          </linkcontainer>
          {/* <linkcontainer to="/tilaa">
            <nav.link>Beställ</nav.link>
          </linkcontainer> */}
          <linkcontainer to="/yhteydenotto">
            <nav.link>Kontakta oss</nav.link>
          </linkcontainer>
          <linkcontainer to="/usein_kysyttya">
            <nav.link>Vanliga frågor och svar</nav.link>
          </linkcontainer>
          <linkcontainer to="/yrityksille">
            <nav.link>För företag</nav.link>
          </linkcontainer>
          <linkcontainer to="/aboutus">
            <nav.link>Om oss</nav.link>
          </linkcontainer>
          <linkcontainer to="/happysenior_blogi">
            <nav.link>Happysenior-bloggen</nav.link>
          </linkcontainer>
          {user.isLoggedIn ? (
            <navdropdown title="Inloggad" id="user-firstname">
              <linkcontainer to="/user">
                <navdropdown.item>Konto</navdropdown.item>
              </linkcontainer>
              <navdropdown.item onClick="{handleLogout}">
                Logga ut
              </navdropdown.item>
            </navdropdown>
          ) : (
            <linkcontainer to="/kirjaudu">
              <nav.link>Logga in</nav.link>
            </linkcontainer>
          )}
          {user && (user.isAdmin || user.role === "it_support") && (
            <navdropdown title="Admin" id="adminmenu">
              <linkcontainer to="/admin/users">
                <navdropdown.item>Kunder</navdropdown.item>
              </linkcontainer>
              <linkcontainer to="/admin/services">
                <navdropdown.item>Tjänster</navdropdown.item>
              </linkcontainer>
              <linkcontainer to="/admin/orders">
                <navdropdown.item>Beställningar</navdropdown.item>
              </linkcontainer>
              <linkcontainer to="/admin/coupons">
                <navdropdown.item>Kuponger</navdropdown.item>
              </linkcontainer>
              <linkcontainer to="/admin/senior_homes">
                <navdropdown.item>Äldreboenden</navdropdown.item>
              </linkcontainer>
              <linkcontainer to="/admin/nurse">
                <navdropdown.item>Sjuksköterska</navdropdown.item>
                {/* Sjuksköterska */}
              </linkcontainer>
              {user && user.isAdmin && (
                <>
                  <linkcontainer to="/admin/alerts">
                    <navdropdown.item>Varningar</navdropdown.item>
                  </linkcontainer>
                  <linkcontainer to="/admin/blogs">
                    <navdropdown.item>Blogg</navdropdown.item>
                  </linkcontainer>
                  <linkcontainer to="/admin/it_support">
                    <navdropdown.item>IT-stöd</navdropdown.item>
                    {/* IT-stöd */}
                  </linkcontainer>
                </>
              )}
            </navdropdown>
          )}
          <div className="weglot"></div>
        </nav>
      </navbar.collapse>
    </navbar>
  );
};

export standard NavBar;
