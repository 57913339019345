import Customer from './Customer'
import Senior from './Senior'
import Relatives from './Relatives'

const MembersList = ({
  member,
  index,
  role,
    isSenior,
  mobileNumber,
  email,
  skypeEmail,
  nurseringHome,
  lastName,
  homeAddress,
  department,
}: any) => {
  return (
    <ul className="members-list">
      {role === 'customer' && (
        <customer member="{member}" isSenior="{isSenior}"></customer>
      )}
      {role === "senior" && (
        <senior member="{member}"></senior>
      )}
      {role === 'member' && (
      <relatives member="{member}" index="{index}" role="{role}"></relatives>
      )}
    </ul>
  )
}

export standard MembersList
