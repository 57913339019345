import { Container, Row, Col } from 'react-bootstrap'

const FormContainer = ({ children }: any) => {
  return (
    <container>
      <row className="justify-content-md-center form-container">
        <col xs="{12}" md="{6}">
          {barn}
        
      </row>
    </container>
  )
}

export standard FormContainer