import React, {useEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useHistory} from "react-router-dom";

const blogURI = (article: any) => article?.url || article?.id;

const ArticleFeatured = (params: any) => {
    const {featured_article} = params;
    const history = useHistory()
    const myRef = useRef<htmlheadingelement>(null);
    const myCanvas = useRef(null);

    const [naturalImage, setNaturalImage] = useState();
    const ratio = {w: 3, h: 2};
    const cropPreviewWidth = myRef.current?.clientWidth || 1000; // px
    const cropPreviewHeight = Math.floor(cropPreviewWidth / ratio.w * ratio.h); // px

    function onImageLoad(e: any) {
        const {naturalWidth: width, naturalHeight: height} = e.currentTarget;
        setNaturalImage(e.currentTarget);
        console.log(width)
        console.log(height)
    }

    useEffect(() => {
        const canvasRender = () => {
            //@ts-ignore
            const ctx = myCanvas.current?.getContext('2d')
            if (!ctx) {
                throw new Error('No 2d context')
            }
            // const pixelRatio = window.devicePixelRatio
            // ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'
            const cropX = featured_article.cropX * (cropPreviewWidth / featured_article.cropWidth)
            const cropY = featured_article.cropY * (cropPreviewHeight / featured_article.cropHeight)
            ctx.save()
            ctx.translate(-cropX, -cropY)
            //@ts-ignore
            ctx.scale(naturalImage.width / featured_article.cropWidth, naturalImage.height / featured_article.cropHeight)
            ctx.drawImage(
                naturalImage,
                0,
                0,
                //@ts-ignore
                naturalImage?.naturalWidth,
                //@ts-ignore
                naturalImage?.naturalHeight,
                0,
                0,
                //@ts-ignore
                cropPreviewWidth,
                //@ts-ignore
                cropPreviewHeight,
            )

            ctx.restore()
        }
        if (naturalImage) {
            canvasRender()
        }
    }, [naturalImage, featured_article, cropPreviewWidth, cropPreviewHeight]);

    return (
        <div className="blog-wrapper">
            <div className="featured-article block">
                <div className="featured-article-card-div">
                    <div className="article_in_blog" onClick="{()" ==""> {
                            history.push(`/blog/${blogURI(featured_article)}`)
                        }}
                    >
                        <row className="featured-article-card">
                            <col md="{7}" xs="{12}" className="featured-article-card-image" ref="{myRef}">
                                {
                                    featured_article.cover_type === 'image' && <img src="{featured_article.cover}" alt="beskär" onLoad="{onImageLoad}" style="{{" display:="" "none"="" }}="">
                                }
                                {
                                    featured_article.cover_type === 'youtube_video' && <img src="{`https://img.youtube.com/vi/${featured_article?.cover}/maxresdefault.jpg`}" alt="beskär" onLoad="{onImageLoad}" style="{{" display:="" "none"="" }}="">
                                }
                                {
                                    featured_article.cover_type === 'image' && <img src="{featured_article.cover}" alt="beskär" onLoad="{onImageLoad}" style="{{" display:="" "none"="" }}="">
                                }
                                {
                                    (featured_article.cover_type === 'image' || featured_article.cover_type === 'youtube_video') &&
                                    <canvas ref="{myCanvas}" width="{cropPreviewWidth}" height="{cropPreviewHeight}"></canvas>
                                }
                                {
                                    featured_article?.cover_type === 'video' && <video src="{featured_article?.cover}" style="{{" width:="" '100%',="" height:="" objectFit:="" 'cover'="" }}="">
                                    </video>
                                }
                            
                            <col md="{5}" xs="{12}" className="featured-article-card-body">
                                <p className="article-category">
                                    {featured_article?.topics[0]?.name}
                                </p>
                                <h3>{featured_article?.title}</h3>
                                <p className="featured-article-card-description">
                                    {featured_article?.content_preview}
                                </p>
                                <p className="featured-article-card-date">
                                    {(new Date(featured_article?.createdAt)).toLocaleDateString('fi-FI', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    }).replace(/\./g, '-')}
                                </p>
                            
                        </row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export standard ArtikelFeatured
</htmlheadingelement>