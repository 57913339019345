const Relatives = ({ member, index, role }: any) => {
  return (
    <div className="relative-members">
      {role === 'member' && (
        <>
          <p className="role">
            <strong>Närstående</strong>
          </p>
          <li className="member">{medlem}</li>
        </>
      )}

    </div>
  )
}

export standard Relatives
