import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Table} from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {deleteAlertRequest, getAlertsRequest} from '../redux/actions'
import {AppState} from '../redux/types'
import {useHistory} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";

const AlertsList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state: AppState) => state.user)
    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getAlertsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const resources = useSelector((state: AppState) => state.alerts)
    const {list, loading, deleted_at, error} = resources

    const deleteHandler = (id: any) => {
        console.log('id', id)
        if (window.confirm('Haluatko varmasti poistaa hälytyksen?')) {
            dispatch(deleteAlertRequest(id))
        }
    }

    const addNewService = () => {
        history.push('/admin/alert/new')
    }

    useEffect(() => {
        dispatch(getAlertsRequest())
    }, [dispatch, deleted_at])

    return (
        <>
            <h1 className="my-3 ml-3" style="{{color:" '#4e5180',="" fontFamily:="" 'Poppins,="" sanf-serif'}}="">Varningar</h1>
            <button onClick="{addNewService}">Lägg till en ny varning</button>
            {Lastning ? (
                <loader></loader>
            ) : error ? (
                <message variant="danger">{fel}</message>
            ) : (
                <table striped="" bordered="" hover="" responsive="" className="users-table">
                    <thead className="user-title">
                    <tr className="user">
                        <th className="text-center">ID</th>
                        <th className="text-center">TEXT</th>
                        <th className="text-center">Starta</th>
                        <th className="text-center">Slut</th>
                        <th className="text-center">{user.readOnly ? 'Katso' : 'Päivitä /Poista'}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list && list.map((alert: any) => (
                        <tr key="{alert.id}">
                            <td>{alert.id}</td>
                            <td>{alert.text}</td>
                            <td>{alert.start}</td>
                            <td>{alert.end}</td>
                            <td>
                                <linkcontainer to="{`/admin/alert/${alert.id}`}">
                                    <button className="btn-sm">
                                        <i className="{user.readOnly" ?="" 'fas="" fa-eye'="" :="" fa-edit'}="" style="{{color:" '#fff'}}=""></i>
                                    </button>
                                </linkcontainer>
                                {!user.readOnly &&
                                    <button variant="danger" className="btn-sm" onClick="{()" ==""> deleteHandler(alert.id)}>
                                        <i className="fas fa-trash"></i>
                                    </button>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </>
    )
}

export standard AlertsList
