import React, {useEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useHistory} from "react-router-dom";
import no_image from "../images/logo.png";

const blogURI = (article: any) => article?.url || article?.id;

const ArticleItem = (params: any) => {
    const {item} = params;
    const history = useHistory()
    const myRef = useRef<htmlheadingelement>(null);
    const myCanvas = useRef(null);

    const [naturalImage, setNaturalImage] = useState();
    const ratio = {w: 3, h: 2};
    const cropPreviewWidth = myRef.current?.clientWidth || 1000; // px
    const cropPreviewHeight = Math.floor(cropPreviewWidth / ratio.w * ratio.h); // px

    function onImageLoad(e: any) {
        const {naturalWidth: width, naturalHeight: height} = e.currentTarget;
        setNaturalImage(e.currentTarget);
        console.log(width)
        console.log(height)
    }

    useEffect(() => {
        const canvasRender = () => {
            //@ts-ignore
            const ctx = myCanvas.current?.getContext('2d')
            if (!ctx) {
                throw new Error('No 2d context')
            }
            // const pixelRatio = window.devicePixelRatio
            // ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'
            const cropX = item.cropX * (cropPreviewWidth / item.cropWidth)
            const cropY = item.cropY * (cropPreviewHeight / item.cropHeight)
            ctx.save()
            ctx.translate(-cropX, -cropY)
            //@ts-ignore
            ctx.scale(naturalImage.width / item.cropWidth, naturalImage.height / item.cropHeight)
            ctx.drawImage(
                naturalImage,
                0,
                0,
                //@ts-ignore
                naturalImage?.naturalWidth,
                //@ts-ignore
                naturalImage?.naturalHeight,
                0,
                0,
                //@ts-ignore
                cropPreviewWidth,
                //@ts-ignore
                cropPreviewHeight,
            )

            ctx.restore()
        }
        if (naturalImage) {
            canvasRender()
        }
    }, [naturalImage, item.cropX, item.cropY, item.cropWidth, item.cropHeight,
        cropPreviewHeight, cropPreviewWidth]);

    return (
        <div className="article_in_blog" onClick="{()" ==""> {
                history.push(`/blogg/${blogURI(objekt)}`)
            }}
        >
            <row className="article-card column">
                <col xs="{12}" className="article-card-image" style="{{" background:="" `top="" center="" contain="" no-repeat="" url(${no_image})`,="" width:="" `${cropPreviewWidth}px`,="" height:="" `${cropPreviewHeight}px`,="" }}="" ref="{myRef}">
                    {
                        item.cover_type === 'image' && <img src="{item.cover}" alt="beskär" onLoad="{onImageLoad}" style="{{" display:="" "none"="" }}="">
                    }
                    {
                        item.cover_type === 'youtube_video' && <img src="{`https://img.youtube.com/vi/${item?.cover}/maxresdefault.jpg`}" alt="beskär" onLoad="{onImageLoad}" style="{{" display:="" "none"="" }}="">
                    }
                    {
                        item.cover_type === 'image' && <img src="{item.cover}" alt="beskär" onLoad="{onImageLoad}" style="{{" display:="" "none"="" }}="">
                    }
                    {
                        (item.cover_type === 'image' || item.cover_type === 'youtube_video') &&
                        <canvas ref="{myCanvas}" width="{cropPreviewWidth}" height="{cropPreviewHeight}"></canvas>
                    }
                    {
                        item.cover_type === 'video' && <video src="{item.cover}" style="{{" width:="" `${cropPreviewWidth}px`,="" height:="" `${cropPreviewHeight}px`,="" objectFit:="" 'cover'="" }}="">
                        </video>
                    }
                
                <col xs="{12}" className="article-card-body">
                    <p className="article-category">
                        {item.topics[0]?.name}
                    </p>
                    <h3 title="{item.title}">
                        {item.title}
                    </h3>
                    <p className="featured-article-card-description">
                        {item.content_preview}
                    </p>
                    <p className="featured-article-card-date">
                        {(new Date(item.createdAt)).toLocaleDateString('fi-FI', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        }).replace(/\./g, '-')}
                    </p>
                
            </row>
        </div>
    )
}

export standard ArtikelItem
</htmlheadingelement>