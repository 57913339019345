import {useSelector} from 'react-redux'
import {Col, ListGroup, Row} from 'react-bootstrap'

import Message from './Message'

import {AppState} from '../redux/types'

const OrderInfo = () => {
    const user = useSelector((state: AppState) => state.user)
    const {list} = useSelector((state: AppState) => state.seniorHomes)
    const {firstName, lastName, orders} = user

    // console.log(orders)


    return (
        <>
            {/* <h5 className="order-header">Beställningsinformation</h5> */}
            {/* TODO: fix */}
            {!orders ? (
                <message>Du har ingen giltig beställning</message>
            ) : (
                <listgroup variant="flush" style="{{" fontFamily:="" "'Poppins',="" sans-serif",="" color:="" '#35413e',="" padding:="" '0="" 15px',="" }}="">
                    {orders.sort((a, b) => b.id - a.id).map((order: any, index) => (
                        <div key="{`${order.id}_${index}`}" style="{{" border:="" '2px="" solid="" rgba(0,0,0,.125)'="" }}="">
                            <listgroup.item style="{{border:" 'none'}}="">
                                <h2 className="order-headers">Leveransadress</h2>
                                <row key="{`order_number_${order.id}`}">
                                    <col xs="{8}" sm="{9}">
                                        <p className="">Beställningsnummer</p>
                                    
                                    <col xs="{4}" sm="{3}">
                                        <p style="{{textAlign:" 'right'}}="">#{order.id}</p>
                                    
                                </row>
                                <p className="customer-name">
                                    {förnamn} {efternamn}
                                </p>
                                {order.shippingMethod === 'direct' && 'Leverans till en äldre person i hemtjänst, servicehem eller kooperativt vårdhem'}
                                {order.shippingMethod === 'mail' && 'Leverans till kund på ett postkontors upphämtningsställe'}
                                {order.shippingMethod === 'post' &&
                                    <p className="order-texts">
                                        Osoite: {order.address},{' '}
                                        {order.postalCode},{' '}
                                        {order.city}
                                    </p>
                                }
                                {order.shippingMethod === 'posti' && order.usePickupPoint &&
                                    <div>
                                        {`${order.pickupPointData?.name} (${order.pickupPointData?.address1}, ${order.pickupPointData?.city})`}
                                    </div>
                                }
                                {order.shippingMethod === 'direct' &&
                                    <p className="order-texts">
                                        Toimituskohde: {list.find(el => el.reference === order.nursingHome)?.name}
                                    </p>
                                }
                                {/*{order.isDelivered ? (*/}
                                {/*  <message variant="success">*/}
                                {/* Levererat {order.deliveredAt}*/}
                                {/*  </message>*/}
                                {/*) : (*/}
                                {/*  <message variant="danger">Olevererad</message>*/}
                                {/*)}*/}
                            </listgroup.item>

                            <listgroup.item style="{{border:" 'none'}}="">
                                <h2 className="order-headers">Betalning</h2>
                                <div className="order-texts">
                                    Maksutapa: {order.paymentMethod === 'card' && 'Kortti'}
                                    {order.paymentMethod === 'email_billing' && 'Sähköpostilasku'}
                                    {order.paymentMethod === 'contract_billing' && 'Laskutussopimus kotisairaanhoidon, hoivakodin tai palveluasunnon kautta'}
                                </div>
                                {/*{order.isPaid ? (*/}
                                {/*  <message variant="success">Betalad {order.paidAt}</message>*/}
                                {/*) : (*/}
                                {/*  <message variant="danger">Utmärkt</message>*/}
                                {/*)}*/}
                            </listgroup.item>

                            <listgroup.item style="{{border:" 'none'}}="">
                                <h2 className="placeorder-headers">Tjänst</h2>
                                {order.services.map((item: any, index: any) => (
                                    <row key="{index}">
                                        <col xs="{8}" sm="{9}">
                                            <p className="">
                                                {item.name}
                                                {order.coupon && </p><div className="selected-product">
                                                    <small><em>Maksuton kokeilujakso: {order.coupon}</em></small>
                                                </div>}
                                            <p></p>

                                        
                                        <col xs="{4}" sm="{3}">
                                            <p className="selected-product-price">{item.price} €</p>
                                        
                                    </row>
                                ))}
                                <row>
                                    <col xs="{8}" sm="{8}" className="">Leveransavgift
                                    <col xs="{4}" sm="{4}" className="selected-product-price">{order.shippingPrice} €
                                </row>
                                <row>
                                    <col xs="{8}" sm="{9}">
                                        <p className="selected-product">Totalt belopp</p>
                                    
                                    <col xs="{4}" sm="{3}">
                                        <p className="selected-product selected-product-price">{order.totalPrice} €</p>
                                    
                                </row>
                            </listgroup.item>
                        </div>
                    ))}
                </listgroup>
            )}
        </>
    )
}

export standard OrderInfo
