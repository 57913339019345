import { useSelector } from "react-redux";
import { Accordion, Tabs } from "react-bootstrap";

import { AppState } from "../redux/types";
import { useEffect, useRef, useState } from "react";

const Tab = ({ key1, onSelect, userForm, memberForm, seniorDetail, featureChoices, orders }: any) => {
    const user = useSelector((state: AppState) => state.user);
    // const members = useSelector((state: AppState) => state.user.group?.members)
    // const hasSeniorMember = members?.find(el => el.role === 'senior')
    const tab4Ref = useRef<htmlheadingelement>(null);
    const [width, setWidth] = useState(1000);
    useEffect(() => {
        if (tab4Ref.current) {
            console.log(tab4Ref?.current?.clientWidth);
            setWidth(tab4Ref?.current?.clientWidth - 32);
        }
    }, [tab4Ref]);
    const category = useSelector((state: AppState) => state.categories.category?.attributes.slug);
    return (
        <>
            {key1 && user.role === "customer" && (
                <tabs id="controlled-tab" activeKey="{key1" ||="" "tilaus"}="" onSelect="{onSelect}">
                    <tab eventKey="tilaus" title="Beställningsinformation">
                        {beställningar}
                    </tab>
                    <tab eventKey="asiakas" title="1 Mina kontaktuppgifter">
                        {userForm}
                    </tab>
                    <tab eventKey="seniori" title="2 Lägg till senior" disabled="{user.isSenior}">
                        {seniorDetail}
                    </tab>
                    <tab eventKey="laheinen" title="3 Lägg till närstående">
                        {memberForm}
                    </tab>
                    <tab id="tab4" style="{{" flexShrink:="" "max-content",="" }}="" eventKey="raatalointi" title="{" <div="" ref="{tab4Ref}" display:="" "flex",="" flexFlow:="" "wrap",="" width:="" "100%",="" maxWidth:="" width,="">
                                <div style="{{" width:="" "100%",="" }}="">
                                    4 Räätälöi {category === "phone" ? "puhelimen" : "tabletin"} ominaisuudet halutessasi
                                </div>
                                <accordion style="{{" paddingLeft:="" 22,="" width:="" "100%",="" }}="">
                                    <accordion.toggle eventKey="0" as="span" onClick="{function" (event)="" {="" event.stopPropagation();="" }}="">
                                        <small>Läs mer</small>
                                    </accordion.toggle>
                                    <accordion.collapse eventKey="0" style="{{" width:="" "inherit",="" }}="">
                                        <small>
                                            {category === "tablet"
                                                ? "Tabletin oletusasetuksina on suomi, videopuhelun automaattinen avautuminen (Helppo ja Huoleton) ja valitun palvelun suosituin sovellusvalikoima."
                                                : "Puhelimen oletusasetuksina on suomi, videopuhelun avautuminen yhdellä klikillä painamalla vastaa/hylkää ja perussovellusvalikoima. "}
                                        </small>
                                    </accordion.collapse>
                                </accordion>
                            </tab></tabs></htmlheadingelement>