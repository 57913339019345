import SubNavBar from '../components/SubNavBar'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../redux/types";
import {useHistory, useParams} from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import React, {useEffect, useRef, useState} from "react";
import {getBlogsRequest} from "../redux/actions";

const Article = () => {
    const {id} = useParams<{ id: string }>()
    console.log(id)
    const myRef = useRef<htmlheadingelement>(null);

    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getBlogsRequest())
        return () => {
        }
    }, [dispatch, history])

    const resources = useSelector((state: AppState) => state.blogs)
    const {list} = resources
    console.log(list)
    const article = list.find(el => el.url === id || el.id === parseInt(id))
    console.log(article)
    const [naturalImage, setNaturalImage] = useState();
    const ratio = {w: 3, h: 2};
    const cropPreviewWidth = myRef.current?.clientWidth || 1000; // px
    const cropPreviewHeight = Math.floor(cropPreviewWidth / ratio.w * ratio.h); // px

    function onImageLoad(e: any) {
        const {naturalWidth: width, naturalHeight: height} = e.currentTarget;
        setNaturalImage(e.currentTarget);
        console.log(width)
        console.log(height)
    }

    useEffect(() => {
        const canvasRender = () => {
            var myCanvas = document.getElementById('myCanvas');
            //@ts-ignore
            const ctx = myCanvas.getContext('2d')
            if (!ctx) {
                throw new Error('No 2d context')
            }
            // const pixelRatio = window.devicePixelRatio
            // ctx.scale(pixelRatio, pixelRatio)
            ctx.imageSmoothingQuality = 'high'
            const cropX = article.cropX * (cropPreviewWidth / article.cropWidth)
            const cropY = article.cropY * (cropPreviewHeight / article.cropHeight)
            ctx.save()
            ctx.translate(-cropX, -cropY)
            //@ts-ignore
            ctx.scale(naturalImage.width / article.cropWidth, naturalImage.height / article.cropHeight)
            ctx.drawImage(
                naturalImage,
                0,
                0,
                //@ts-ignore
                naturalImage?.naturalWidth,
                //@ts-ignore
                naturalImage?.naturalHeight,
                0,
                0,
                //@ts-ignore
                cropPreviewWidth,
                //@ts-ignore
                cropPreviewHeight,
            )

            ctx.restore()
        }

        if (naturalImage) {
            canvasRender()
        }
    }, [naturalImage, article, cropPreviewWidth, cropPreviewHeight]);

    return (
        <>
            <subnavbar></subnavbar>
            {artikel ? <div>
                <div className="section-wrapper article-header-container">
                    <div>
                        <div className="article-header block">
                            <div className="article-category article-view">

                            </div>
                            <div className="article-title  article-view">
                                <div>
                                    <h1>
                                        {article.title}
                                    </h1>
                                </div>
                            </div>
                            <div className="article-byline article-view">
                                <div className="article-byline-info">

                                </div>
                            </div>
                            {article.cover?.length && <div className="article-feature-image" ref="{myRef}">
                                {
                                    article.cover_type === 'image' && <img src="{article.cover}" alt="beskär" onLoad="{onImageLoad}" style="{{" display:="" "none"="" }}="">
                                }
                                {
                                    article.cover_type === 'image' && <canvas id="myCanvas" width="{cropPreviewWidth}" height="{cropPreviewHeight}"></canvas>
                                }
                                {
                                    article.cover_type === 'video' && <video src="{article.cover}" style="{{" width:="" '100%',="" height:="" `${cropPreviewHeight}px`,="" background:="" '#000000',="" }}="" controls="">
                                    </video>
                                }
                                {article.cover_type === "youtube_video" && <iframe height="100%" src="{`https://www.youtube.com/embed/${article.cover}`}" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" title="{article.title}" style="{{" margin:="" '0="" auto',="" width:="" '100%',="" height:="" `${cropPreviewHeight}px`="" }}=""></iframe>
                                }
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="article-content" data-color-mode="light">
                    <mdeditor.markdown source="{article.content}/">
                </mdeditor.markdown></div>

                <div className="tags block article-topics">
                    <div>
                        <div>
                            <p className="tags-container">
                                {article.topics?.map((item: any) => {
                                    console.log(item)
                                    return item?.name?.length ? <span className="button">
                                    {item.name}
                                </span> : null
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </div> : <div>
                404
            </div>
            }
        </>
    )
}

export standard Artikel
</htmlheadingelement>