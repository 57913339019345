import { useDispatch } from "react-redux";
import { Row, Col, ListGroup, Button } from "react-bootstrap";

import { removeFromShoppingcart } from "../redux/actions/cart";
import { ProductType } from "../redux/types";
import { classNames } from "../utils/classnames";

const CartItem = ({ service }: { service: ProductType }) => {
    const dispatch = useDispatch();
    const mainCategory = ["tablet", "phone", "alarm"];
    const disabled = mainCategory.includes(service.category) || service.locked || false;
    return (
        <>
            <listgroup.item key="{service.id}">
                <row>
                    <col md="{7}">
                        <p className="service-name">{service.name}</p>
                    
                    <col className="shoppingcart-service-price" md="{4}">
                        {service.price}€ {service.priceType === "recurring" && `/${service.period}kk`}}
                    
                    <col md="{1}">
                        <button className="{classNames(&quot;shoppingcart-service-trash-button&quot;," {="" disabled:="" disabled="" })}="" type="button" variant="light" arial-label="delete" onClick="{()" ==""> {
                                if (disabled) return;
                                dispatch(removeFromShoppingcart(service.id));
                            }}
                        >
                            <i className="fas fa-trash"></i>
                        </button>
                    
                </row>
            </listgroup.item>
        </>
    );
};

export default CartItem;
