import React, { CSSProperties, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode, useEffect } from "react";
import { getAlertsRequest } from "../../redux/actions";
import { AppState } from "../../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../../components/button/Button";
import Banner from "../../components/layout/banner/Banner";
import styles from "./phone.module.css";
import { classNames } from "../../utils/classnames";

export type ServicesType = {
    content: ReactNode;
    image: string;
    credit?: string;
    alt?: string;
};
const Phone = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const services: ServicesType[] = [
        {
            content: (
                <>
                    <div className="services-info-title">Telefon, tillbehör </div>
                    <ul className="services-info-text">
                        <li>
                            <a href="https://www.samsung.com/fi/smartphones/galaxy-a/galaxy-a15-5g-blue-128gb-sm-a156bzbdeub" target="_blank" rel="noreferrer">
                                <span className="global-text global-text-strong">Samsung Galaxy</span>
                            </a>{" "}
                            A15 5G, 128 GB
                        </li>
                        <li>Android 14, blå</li>
                        <li>Valfria tillägg enligt önskemål</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/senoripuhelin.webp",
            alt: "Nainen pitää kädessään sinistä puhelinta",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Lättanvänd hemvy och funktioner</div>
                    <ul className="services-info-text">
                        <li>Hemvyn är enkel</li>
                        <li>Funktioner anpassade för att underlätta användningen</li>
                        <li>
                            Nödvändiga appar är redo
                            <ul>
                                <li>Endast en vy med videosamtal eller sms, kamera, nyheter - och möjlighet att lägga till egna appar</li>
                            </ul>
                        </li>
                        <li>Om kunden senare slutar</li>
                        <p>Från Digihappy-tjänsten kan din telefon att återgå till en vanlig Samsung-smartphone.</p>
                        <a href="https://www.youtube.com/watch?v=ThIlFx8pbn8" className="global-link" target="_blank" rel="noreferrer">
                            Titta på videon
                        </a>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/paras-senioripuhelin.webp",
            alt: "Henkilö pitelee älypuhelinta, jonka näytöllä näkyy erilaisia sovelluksia",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Enkla och säkra videosamtal</div>

                    <ul className="services-info-text">
                        <li>Digihappy videosamtalsapp finns på seniorens och de närståendes telefoner</li>
                        <li>När en anhörig ringer öppnas seniorens video utan beröring om så önskas</li>
                        <li>Senioren ringer genom att trycka på bilden av den närstående</li>
                        <li>Säkert, endast familjekontots medlemmar kan ringa</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/videopuhelu-senioripuhelimella.webp",
            alt: "Älypuhelin pöydällä näyttää videopuhelua, jossa on nainen",
        },
        {
            content: (
                <>
                    <div className="services-info-title">Familjekonto för videosamtal</div>
                    <ul className="services-info-text">
                        <li>Du kan ansluta sex familjemedlemmar eller vänner till tjänsten. En medlem kan ringa ett videosamtal, som automatiskt öppnas på seniorens surfplatta om så önskas. Medlemmens bild visas i seniorens videosamtalsvy.</li>
                        <li>Medlemmen har tillgång till IT-stöd.</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/helpot-videopuhelut-senioripuhelimella.webp",
            alt: "Uusi Samsung Galaxy S10 5G ja kosketusnäyttökynä pöydällä. ",
        },
        {
            content: (
                <>
                    <div className="services-info-title">IT-support, fjärrhantering, säkerhet, uppdateringar</div>
                    <ul className="services-info-text">
                        <li>Videohandledning i början</li>
                        <li>Kontinuerlig IT-support, telefon- och videokonferenser, kommunikationskanal</li>
                        <li>Fjärrhantering av din telefon, även utan seniorens aktiva medverkan</li>
                        <li>Datasäkerhet och uppdateringar</li>
                    </ul>
                </>
            ),
            image: "/assets/images/phone/5.webp",
        },
    ];

    const [table, openTable] = useState(false);
    const [play, setPlay] = useState(0);

    return (
        <>
            <helmet>
                <title>Seniortelefon som tjänst | Bästa funktioner och IT-support</title>
                <meta name="description" content="En seniormobil med allt du behöver, men inte allt du inte behöver. Enkla videosamtal. Redo att användas, it-support.">
            </helmet>
            <banner 56="" backgroundImage="/assets/images/banners/helpot-videopuhelut.webp" label="Nainen pitelee älypuhelinta, jonka näytöllä näkyy yhteystietoluettelo" height="{`calc(100vh" -="" ${="" +="" *="" list?.filter((el)=""> {
                            // @ts-ignore
                            return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                        })?.length -
                    1
                }px)`}
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title global-white">Seniortelefon som tjänst</h1>
                    <p className="global-banner-text global-white">Den enklaste smarttelefonen för seniorer. Allt du behöver, inget onödigt.</p>
                    <link to="/osta/senioripuhelin" style="{{" marginTop:="" "auto"="" }}="">
                        <button secondary="" emphasized="">
                            KÖP EN SENIORTELEFON
                        </button>
                    
                </div>
            </banner>
            <container fluid="" className="my-3 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <h1 className="services-header">Telefon och Digihappy-tjänsten - Vi gör allt åt dig</h1>
                <div className="global-box-line-container">
                    {services.map((tjänst, nyckel) => (
                        <div className="global-box-line" key="{key}">
                            {key % 2 === 0 ? (
                                <react.fragment>
                                    <div className="global-box global-background-gray">{tjänst.innehåll}</div>
                                    <div className="global-image-container">
                                        <img src="{service.image}" alt="{service.alt}" className="global-image">
                                    </div>
                                </react.fragment>
                            ) : (
                                <react.fragment>
                                    <div className="global-image-container">
                                        <img src="{service.image}" alt="{service.alt}" className="global-image">
                                    </div>
                                    <div className="global-box global-background-gray">{tjänst.innehåll}</div>
                                </react.fragment>
                            )}
                        </div>
                    ))}
                </div>
            </container>
            <container fluid="" className="my-3 services-container service-table-container" id="palvelut">
                <div className="{styles.serviceContainer}">
                    <h1 600="" className="services-header text-center" style="{{" marginBottom:="" 0,="" fontWeight:="" }}="">
                        Jämförelse av smarttelefoner för seniorer
                    </h1>
                    <p className="{styles.description}" id="best">
                        Digihappy seniorsmarttelefon utmärker sig när det gäller användarvänlighet, prestanda och service jämfört med liknande produkter.
                    </p>
                    <div className="{styles.tableDropdownContainer}" onClick="{()" ==""> openTable((table) => !table)}>
                        <span className="{styles.dropDownText}">Öppna jämförelsen av smarttelefoner för seniorer</span>
                        <div className="{table" ?="" styles.iconOpen="" :="" styles.icon}="">
                            <img className="{styles.dropDownIcon}" src="/assets/icons/arrow_left.svg" alt="<">
                        </div>
                    </div>
                    <div className="{table" ?="" styles.tableOpen="" :="" styles.tableHidden}="">
                        <comparisontable></comparisontable>
                        <p className="{classNames(styles.bottomText," "global-text-small="" text-center")}="">
                            Den första månaden får du Digihappys IT-support kostnadsfritt och utan några begränsningar. Efter denna period är individuell IT-support kostnadsfri i 45 minuter/månad. Ytterligare support kostar 10 €/10 minuter,
                            minsta avgift är 10 €/samtal. Vid fel på en tjänst eller utrustning som är relaterad till oss är lösningen alltid kostnadsfri för dig.
                        </p>
                    </div>
                </div>
                <div className="services-button-container">
                    <button onClick="{()" ==""> setPlay(1)}>Visa video</button>
                    <div className="global-video-wrapper">
                        <iframe 16="" src="{`https://www.youtube.com/embed/ThIlFx8pbn8?autoplay=${play}`}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen="" title="Digihappy Seniortelefon" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" height:="" border:="" "none",="" aspectRatio:="" 9,="" }}=""></iframe>
                    </div>
                </div>
            </container>
        </>
    );
};

const ComparisonTable = () => (
    <div className="palvelut-table-div">
        <table className="{classNames(&quot;palvelut-table&quot;," styles.table)}="">
            <colgroup>
                <col style="{{" width:="" "40%",="" }}="">
                <col style="{{" width:="" "20%",="" }}="">
                <col style="{{" width:="" "20%",="" }}="">
                <col style="{{" width:="" "20%",="" }}="">
            </colgroup>
            <tbody>
                <tr>
                    <td></td>
                    <td className="{styles.header}">
                        <h1 className="accordion-button-header">Digihappy / Samsung</h1>
                    </td>
                    <td className="{styles.header}">
                        <h1 className="accordion-button-header">Samsung</h1>
                    </td>
                    <td className="{styles.header}">
                        <h1 className="accordion-button-header">Doro</h1>
                    </td>
                </tr>
                <tr>
                    <td>Telefonmodell</td>
                    <td>Samsung Galaxy A15</td>
                    <td>Samsung Galaxy A15</td>
                    <td>Doro 8210</td>
                </tr>
                <tr>
                    <td>Nätanslutning</td>
                    <td>5G</td>
                    <td>5G</td>
                    <td>4G</td>
                </tr>
                <tr>
                    <td>Operativsystem</td>
                    <td>Android 14</td>
                    <td>Android 14</td>
                    <td>Android 12</td>
                </tr>
                <tr>
                    <td>Kamera</td>
                    <td>50 MP</td>
                    <td>50 MP</td>
                    <td>16 MP</td>
                </tr>
                <tr>
                    <td>Minne</td>
                    <td>4G/128 GB</td>
                    <td>4G/128 GB</td>
                    <td>4G/64 GB</td>
                </tr>
                <tr>
                    <td>Vattentålighet</td>
                    <td>Ej angiven</td>
                    <td>Ej angiven</td>
                    <td>Stänk</td>
                </tr>
                <tr>
                    <td>Batteritid - samtalstid</td>
                    <td>44 timmar</td>
                    <td>44 timmar</td>
                    <td>23 timmar</td>
                </tr>
                <tr>
                    <td>Snabbladdning</td>
                    <td>1 timme 25 minuter</td>
                    <td>1 timme 25 minuter</td>
                    <td>ingen snabbladdning</td>
                </tr>
                <tr>
                    <td>Prestanda - Geekbench - ju större värde desto bättre</td>
                    <td>684/1831</td>
                    <td>684/1831</td>
                    <td>182/800</td>
                </tr>
                <tr>
                    <td>Nödsamtal genom att trycka på knappen</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Anpassad hemvy och funktioner </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Klar att använda - optimerade inställningar </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Klar att använda - även abonnemanget</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Stora ikoner</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between"="" }}="">
                            <star></star>
                            <span style="{{" textAlign:="" "right"="" }}="">
                                delvis <br/>
                                tillgänglig på
                            </span>
                        </div>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Alla appar på en och samma vy</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Inga onödiga appar</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between"="" }}="">
                            <star></star>
                            <span style="{{" textAlign:="" "right"="" }}="">
                                delvis <br/>
                                tillgänglig på
                            </span>
                        </div>
                    </td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>hemvyn fungerar utan swipening</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Stor hemknapp</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between",="" flexWrap:="" "wrap",="" alignItems:="" "flex-end"="" }}="">
                            <div>
                                <star></star>
                                <star></star>
                            </div>
                            <span style="{{" textAlign:="" "right",="" marginLeft:="" "auto"="" }}="">tillgänglig på</span>
                        </div>
                    </td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Ljud, teckensnitt, låst orientering</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between",="" flexWrap:="" "wrap",="" alignItems:="" "flex-end"="" }}="">
                            <div>
                                <star></star>
                                <star></star>
                            </div>
                            <span style="{{" textAlign:="" "right",="" marginLeft:="" "auto"="" }}="">tillgänglig på</span>
                        </div>
                    </td>
                    <td>
                        <div style="{{" display:="" "flex",="" columnGap:="" "2px",="" justifyContent:="" "space-between",="" flexWrap:="" "wrap",="" alignItems:="" "flex-end"="" }}="">
                            <div>
                                <star></star>
                                <star></star>
                            </div>
                            <span style="{{" textAlign:="" "right",="" marginLeft:="" "auto"="" }}="">tillgänglig på</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        Enkla videosamtal med din familj <br/>
                        (Digihappy app)
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Svar på videosamtal även utan beröring</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Uppringning genom att trycka på den stora bilden av den närstående</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Inledande rådgivning</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Kontinuerlig IT-support, uppdateringar</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Fjärrhantering som en del av IT-tjänsten</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Fjärrhantering av en närstående</td>
                    <td>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                    </td>
                </tr>
                <tr>
                    <td>Efter en senioranvändare kan telefonen enkelt uppgraderas till en högkvalitativ smartphone</td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td>
                        <star></star>
                        <star></star>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td className="{styles.top}">Pris juli 2024</td>
                    <td className="{styles.top}">165€ telefon 35€ installation, inledande konsultation 11€/månad kontinuerlig it-support, fjärrhantering, videosamtalsapp, anpassad vy och funktioner</td>
                    <td className="{styles.top}">149€- 239 €</td>
                    <td className="{styles.top}">275€-339€</td>
                </tr>
            </tbody>
        </table>
    </div>
);

const Star = ({ style }: { style?: CSSProperties }) => {
    return <img className="{styles.star}" src="/assets/icons/star.svg" alt="*" style="{style}">;
};

export default Phone;
