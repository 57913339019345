import SubNavBar from '../components/SubNavBar'
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../redux/types";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {getBlogsRequest} from "../redux/actions";
import {Helmet} from "react-helmet";
import ArticleFeatured from "../components/ArticleFeatured";
import ArticleItem from "../components/ArticleItem";


const Health = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getBlogsRequest())
        return () => {
        }
    }, [dispatch, history])

    const resources = useSelector((state: AppState) => state.blogs)
    const {list} = resources
    console.log(list)
    const featured_article = list.find(el => el.featured) || list[0] || {
        id: null,
        title: '',
        topics: [{name: ''}],
        cover: '',
        createdAt: '',
        content_preview: '',
    };
    return (
        <>
            <helmet>
                <meta name="description" content="En blogg om hälsa och välbefinnande för seniorer. Och tips om hur man använder surfplattan på ett smidigt sätt. Skrivet av experter inom medicin, välbefinnande och digitalisering.">
            </helmet>
            <subnavbar></subnavbar>
            <div className="blog-wrapper">
            </div>
            {featured_article?.id && <articlefeatured featured_article="{featured_article}"></articlefeatured>}
            {featured_article?.id &&
                <div className="blog-wrapper">
                </div>
            }
            <div className="blog-wrapper">
                <row className="blog-items">
                    {list?.filter(el => el.id !== featured_article.id)?.map(item => {
                        returnera <col 48="" md="{4}" style="{{" marginBottom:="" }}="" key="{`col_article_${item?.id}`}">
                            <articleitem item="{item}"></articleitem>
                        
                    })
                    }
                </row>
            </div>
        </>
    )
}

export default Health
