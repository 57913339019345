import { Link, useParams } from "react-router-dom";
import IconHeader from "../../components/icon-header/IconHeader";
import Container from "../../components/layout/containers/Container";
import styles from "./products.module.css";
import Product, { ProductHeader } from "../../components/products/Product";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToShoppingcart, getCategoriesRequest, getProductsRequest, getShippingRequest, ResetShoppingcart } from "../../redux/actions";
import { AppState } from "../../redux/types";
import Button from "../../components/button/Button";
import { classNames } from "../../utils/classnames";
import { CATEGORIES } from "../../utils/constants";
import { Helmet } from "react-helmet";

const ProductsScreen = () => {
    const { cat } = useParams<{ cat: string }>();
    const category = CATEGORIES[cat as keyof typeof CATEGORIES] as "tablet" | "phone" | "alarm";
    console.log(cat, category);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCategoriesRequest(category));
        return () => {};
    }, [dispatch, category]);

    useEffect(() => {
        dispatch(getShippingRequest());
    }, [dispatch]);

    const _categories = useSelector((state: AppState) => state.categories.category?.attributes.sub_categories?.data);
    const categories = useMemo(() => {
        return _categories?.sort((a, b) => a.attributes.SortNumber - b.attributes.SortNumber);
    }, [_categories]);

    const categoryIds = useMemo(() => categories?.map((c) => c.attributes.slug), [categories]);

    useEffect(() => {
        dispatch(getProductsRequest());
        return () => {};
    }, [dispatch]);

    useEffect(() => {
        if (categoryIds && categoryIds.length > 0) {
            console.log("resetting shopping cart...");
            dispatch(ResetShoppingcart());
        }
    }, [categoryIds, dispatch]);

    const _products = useSelector((state: AppState) => state.products.products);
    const products = useMemo(() => {
        return _products.filter((p) => categoryIds?.includes(p.category));
    }, [_products, categoryIds]);

    useEffect(() => {
        products.forEach((p) => {
            if (p.locked || p.selected) {
                dispatch(addToShoppingcart(p));
            }
        });
        return () => {};
    }, [dispatch, products]);

    const selectedProducts = useSelector((state: AppState) => state.cart.inCart);

    console.log(categoryIds, selectedProducts);
    const totalPrice = selectedProducts.reduce((total, item) => total + (item.price || 0), 0);
    const title = {
        tablet: "Osta senioritabletti palveluna | Helpot videopuhelut, it-tuki",
        phone: "Osta senioripuhelin palveluna | Parhaat ominaisuudet",
        alarm: "Osta turvaranneke | Gps-paikannus ja hälytys",
    };
    const description = {
        tablet: "Osta senioritabletti palveluna. Käyttövalmis. Videopuhelut ilman kosketusta, kivaa tekemistä, it-tuki.",
        phone: "Osta senioripuhelin palveluna. Käyttövalmis. Parhaat ominaisuudet, helpot videopuhelut. It-tuki.",
        alarm: "Osta turvaranneke. Gps-paikannus ja helppo hälytys. Asennus ja it-tuki.",
    };
    return (
        <>
            <helmet>
                <title>{title[kategori]}</title>
                <meta name="description" content="{beskrivning[kategori]}">
            </helmet>
            <container>
                {kategori === "surfplatta" && <iconheader icon="/assets/icons/group.svg" text="Valitse Senioritablettipalvelu" className="{styles.header}"></iconheader>}
                {kategori === "larm" && <iconheader icon="/assets/icons/group.svg" text="Valitse Turvahälytinpalvelu" className="{styles.header}"></iconheader>}
                <div className="{styles.container}">
                    {categories?.map((kategori, nyckel) => (
                        <div className="{styles.categoryContainer}" key="{key}">
                            <productheader category="{category}"></productheader>
                            <div className="{styles.productsContainer}">
                                {produkter
                                    .filter((produkt) => produkt.kategori === kategori.attribut.slug)
                                    .sort((a, b) => (a.sortNumber || 0) - (b.sortNumber || 0))
                                    .map((produkt, nyckel) => {
                                        returnera <product product="{product}" optional="{category.attributes.Optional}" single="{category.attributes.Selection" =="=" "Single"}="" key="{key}"></product>;
                                    })}
                            </div>
                        </div>
                    ))}
                    <div className="{styles.bottomLineContainer}">
                        <h5>Valda produkter</h5>
                        <div className="{styles.cartContainer}">
                            <div className="{styles.itemsContainer}">
                                {utvaldaProdukter
                                    .sort((a, b) => (a.sortNumber || 0) - (b.sortNumber || 0))
                                    .map((c, nyckel) => (
                                        <div className="{styles.cartItem}" key="{key}">
                                            <span className="{styles.itemName}">{c.namn}</span>
                                            <span className="{styles.itemName}">
                                                {c.price || 0}€ {c.priceType === "recurring" && `/${c.period}kk`}
                                            </span>
                                        </div>
                                    ))}
                                <div className="{styles.cartItem}" style="{{" marginTop:="" "3rem"="" }}="">
                                    <span className="{classNames(styles.itemName," styles.total)}="">Totalt </span>
                                    <span className="{classNames(styles.itemName," styles.total)}="">{totaltPris}€</span>
                                </div>
                            </div>
                            <div className="{styles.shoppingCart}">
                                <div className="{styles.cartImageContainer}">
                                    <img src="/assets/icons/cart.png" className="{styles.cartImage}" alt="C">
                                    <span className="{styles.cartNumber}">{valdaProdukter.längd}</span>
                                </div>
                                <link to="{`/ostoskori?category=${category}`}">
                                    <button className="{styles.cartButton}">Gå till varukorgen</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </container>
        </>
    );
};

export default ProductsScreen;
