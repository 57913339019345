import { Container } from "react-bootstrap";
import ServicesAccordionTable from "../components/ServicesAccordionTable";
import ServicesInfo2 from "../components/ServicesInfo2";
import texts from "../texts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAlertsRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../components/button/Button";

const Services = () => {
    const dispatch = useDispatch();

    const currentDate = Date.now();

    useEffect(() => {
        dispatch(getAlertsRequest());
        return () => {};
    }, [dispatch]);

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    const [play, setPlay] = useState(0);
    return (
        <>
            <helmet>
                <title>Senior tablet som tjänst | Enkla videosamtal och IT-support</title>
                <meta name="description" content="Senior Tablet är en enkel och färdig tjänst att använda. Rörelsefria videosamtal, roliga saker att göra, it-support.">
            </helmet>
            <container 56="" fluid="" style="{{" height:="" `calc(100vh="" -="" ${="" +="" *="" list?.filter((el)=""> {
                                // @ts-ignore
                                return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                            })?.length -
                        1
                    }px)`,
                    width: "100vw",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url('/assets/images/banners/paras-tabletti-senorille.webp')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    minHeight: 640,
                }}
                aria-label="Henkilö käyttää tablettia, jonka näytöllä näkyy erilaisia sovelluksia."
                className="img-fluid contact-pic"
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title global-white">{texter[9].text1}</h1>
                    <p className="global-banner-text global-white">
                        {texter[9].text2}
                       <br/>
                        {texter[9].text3}
                    </p>
                    <link to="/osta/senioritabletti" style="{{" marginTop:="" "auto"="" }}="">
                        <button secondary="" emphasized="">
                            Köp en seniorsurfplatta
                        </button>
                    
                </div>
            </container>
            <container fluid="" className="my-3 services-container services-container-80" style="{{" minWidth:="" "min(900px,="" 100%)"="" }}="">
                <h1 className="services-header">
                    <strong>Månadsavgiften</strong> för surfplattetjänsten <strong data-wg-splitted>inkluderar alla</strong>
                </h1>
                <servicesinfo2></servicesinfo2>
            </container>
            <container fluid="" className="my-3 services-container service-table-container" id="palvelut">
                <div>
                    <h1 600="" className="services-header" style="{{" marginBottom:="" 0,="" fontWeight:="" }}="">
                        Vilken tjänst är bäst för senioren?
                    </h1>
                    <p className="what-is-best" id="best">
                        Alla tjänster inkluderar surfplatta, stativ, lång sladd, skyddsfilm, redigering av surfplatta och hemskärm, sim, obegränsad data, videosamtalstjänst, inledande konsultation, it-support, fjärrhantering, uppdateringar, säkerhet,
                        gratis ljudböcker
                    </p>
                </div>
                <servicesaccordiontable></servicesaccordiontable>
                <div className="services-button-container">
                    <link to="/osta/senioritabletti">
                        <button>Köp en seniorsurfplatta</button>
                    
                    <p className="global-text text-normal text-center global-text-dark">
                        Första månaden får du Digihappy IT-support kostnadsfritt och utan några begränsningar. Efter denna period är individuell IT-support kostnadsfri i 45 minuter/månad Ytterligare support kostar 10 €/10 minuter, lägsta avgift
                        är 10 €/samtal. Om det uppstår ett fel på en tjänst eller utrustning som är relaterad till oss är lösningen alltid kostnadsfri för dig. Seniorens surfplatta levereras med en gratis ljudboksapp, som tillhandahålls av Celia.
                        Tjänsten är till för personer som har en läsnedsättning, till exempel en senior med nedsatt syn, muskelfunktion, funktionsförmåga eller minne. Digihappy ger dig råd om hur du får tillgång till tjänsten och får ditt eget ID från biblioteket.
                    </p>
                    <button onClick="{()" ==""> setPlay(1)}>Visa video</button>
                    <div className="global-video-wrapper">
                        <iframe 16="" src="{`https://www.youtube.com/embed/U3AGXnauUQg?autoplay=${play}`}" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen="" title="Digihappy Seniorsurfplattor" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" height:="" border:="" "none",="" aspectRatio:="" 9,="" }}=""></iframe>
                    </div>
                </div>
            </container>
        </>
    );
};

export default Services;
