importera SubNavBar från '../components/SubNavBar'.

const Video = () => {
    returnera (
        <>
            <subnavbar></subnavbar>
        </>
    )
}

export default Video
