import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { getNursesRequest, removeNurseRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import moment from "moment";
import Loader from "../components/Loader";
import Message from "../components/Message";

const Nurses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const nurses = useSelector((state: any) => state.nurses);
  const { list, loading, error, message } = nurses;

  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getNursesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleDeleteNurse = (id: number) => {
    dispatch(removeNurseRequest(id));
  };

  return (
    <>
      <div className="alert-message">
        {Object.keys(message).length !== 0 && (
          <message 200="" variant="{message?.status" =="=" ?="" "info"="" :="" "danger"}="">
            <div className="message-text">{message?.message}</div>
          </message>
        )}
      </div>
      <div className="headind-button-div">
        <h1 className="my-3 ml-3 module-title">Sjuksköterska</h1> {/* Sjuksköterska */}
        {user.isAdmin && (
          <linkcontainer to="{`/admin/nurses/add`}">
            <button className="add-button">LÄGGA TILL EN NY SJUKSKÖTERSKA</button>
            {/* LÄGG TILL EN NY SJUKSKÖTERSKA */}
          </linkcontainer>
        )}
      </div>
      <div>
        {Lastning ? (
          <loader></loader>
        ) : error ? (
          <div className="error-message">
            <message variant="danger">
              <div className="message-text">{error.message}</div>
            </message>
          </div>
        ) : list?.length > 0 ? (
          <table striped="" bordered="" hover="" responsive="" className="users-table">
            <thead className="user-title">
              <tr className="user">
                <th className="text-center">ID</th>
                <th className="text-center">Förnamn</th>
                <th className="text-center">Efternamn</th>
                <th className="text-center">Senior föreskriven</th>
                <th className="text-center">E-postadress</th>
                <th className="text-center">Telefonnummer</th>
                <th className="text-center">Datum för uppdatering</th>
                <th className="text-center">Vårdhem</th>
                <th className="text-center">
                  {user.isAdmin ? "Päivitä /Poista" : "Näkymä"}
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item: any) => (
                <tr key="{item.userId}">
                  <td className="text-center">{item.userId}</td>
                  <td className="text-center">{item.firstName}</td>
                  <td className="text-center">{item.lastName}</td>
                  <linkcontainer to="{`/admin/nurses/seniors/${item.userId}`}" style="{{" color:="" "blue",="" cursor:="" "pointer"="" }}="">
                    <td className="text-center">{item.seniorIds.length}</td>
                  </linkcontainer>
                  <td className="text-center">{item.email}</td>
                  <td className="text-center">{item.mobileNumber}</td>
                  <td className="text-center">
                    {moment(item?.updatedAt).format("MM/DD/YYYYY")}
                  </td>
                  <td className="text-center">
                    {item.seniorHomes === null ? "-" : item.seniorHomes?.name}
                  </td>
                  <td className="text-center">
                    <linkcontainer className="action-items" to="{`/admin/nurses/${item.userId}`}">
                      <button className="btn-sm">
                        <i className="{" user.isAdmin="" ?="" "fas="" fa-edit"="" :="" fa-eye"="" }="" style="{{" color:="" "#fff"="" }}=""></i>
                      </button>
                    </linkcontainer>
                    {user.isAdmin && (
                      <button variant="danger" className="btn-sm" onClick="{()" ==""> handleDeleteNurse(item.userId)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <message variant="light">Ingen sjuksköterska tillgänglig!!</message>
        )}
      </div>
    </>
  );
};

export default Nurses;
