import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

import { createGroupRequest, getSeniorHomesRequest, getUserRequest, updateUserRequest } from "../redux/actions";
import Message from "./Message";
import Loader from "./Loader";
import { AppState } from "../redux/types";

const UserForm = ({ onSelect }: any) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state: AppState) => state.user);
    useEffect(() => {
        if (user.id) {
            dispatch(getSeniorHomesRequest());
        } else {
            history.push("/kirjaudu");
        }
        return () => {};
    }, [dispatch, history, user]);

    const group = useSelector((state: AppState) => state.user.group);
    const { error, loading, firstName, isLoggedIn } = user;
    const [message, setMessage] = useState("");
    const [isSenior, setIsSenior] = useState(user.isSenior || false);
    const [department, setDepartment] = useState(user.department || "homeAddress");
    const [formData, setFormData] = useState({
        id: user?.id,
        username: user.username || "",
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        mobileNumber: user.mobileNumber || "",
        email: user.email || "",
        password: "",
        isSenior: isSenior || false,
        department: department || "homeAddress",
        homeAddress: user?.homeAddress || "",
        homeCity: user?.homeCity || "",
        homePostalCode: user?.homePostalCode || "",
        nursingHome: user?.nursingHome || "",
        nursingHomeAddress: user?.nursingHomeAddress || "",
    });

    const [showPass, setShowPass] = useState(false);
    const showPassHandler = () => {
        setShowPass((current) => !current);
    };

    if (!isLoggedIn) <redirect to="/kirjaudu"></redirect>;

    // back button
    const back = () => {
        onSelect("tilaus");
    };

    useEffect(() => {
        if (!isLoggedIn) {
            history.push("/kirjaudu");
        } else {
            if (!user.firstName && user.id !== null) {
                dispatch(getUserRequest(user.id));
            } else {
                // user data from db shown in the form
                setFormData((prevValue: any) => {
                    // console.log(prevValue)
                    return {
                        ...prevValue,
                        username: user.username || "",
                        firstName: user.firstName || "",
                        lastName: user.lastName || "",
                        email: user.email || "",
                        password: "",
                        mobileNumber: user.mobileNumber || "",
                        department: user.department || "homeAddress",
                        homeAddress: user?.homeAddress || "",
                        homeCity: user?.homeCity || "",
                        homePostalCode: user?.homePostalCode || "",
                    };
                });
            }
        }
    }, [dispatch, history, user, isLoggedIn]);

    useEffect(() => {
        if (!group) {
            dispatch(createGroupRequest(firstName));
        }
    }, [group, dispatch, firstName]);

    const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        const { value, name } = e.target;

        setFormData((prevValue: any) => {
            return {
                ...prevValue,
                [name]: value,
            };
        });
    };
    const relativeDoneEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeAccountDone);
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        if (formData.password.length > 0 && formData.password.length < 11) {
            setMessage("Salasana on liian lyhyt");
        } else {
            let data = {
                id: formData.id,
                username: formData.username,
                firstName: formData.firstName,
                lastName: formData.lastName,
                mobileNumber: formData.mobileNumber,
                email: formData.email,
                isSenior: isSenior,
                nursingHome: formData.nursingHome,
                nursingHomeAddress: formData.nursingHomeAddress,
                department: department,
                homeAddress: formData?.homeAddress,
                homeCity: formData?.homeCity,
                homePostalCode: formData?.homePostalCode,
                password: formData.password,
                relativeDoneEmailId,
            };
            console.log(data);
            dispatch(updateUserRequest(data));
            if (isSenior) {
                onSelect("laheinen");
            } else {
                onSelect("seniori");
            }
        }
    };

    return (
        <container fluid="">
            <row className="justify-content-md-left">
                <col xs="{12}" md="{6}">
                    {Laddning && <loader></loader>}
                    <h1 className="user-form-header">1 Mina kontaktuppgifter</h1>
                    <form onSubmit="{submitHandler}" className="user-form" autoComplete="off">
                        <form.group controlId="username-customer">
                            <form.label>*E-postadress</form.label>
                            <form.control type="text" placeholder="Sähköpostiosoite" name="username" value="{formData.username}" onChange="{handleChange}"></form.control>
                        </form.group>

                        <form.group controlId="customer-email">
                            <form.label>*Upprepa e-postadress</form.label>
                            <form.control type="email" placeholder="Toista sähköpostiosoite" name="email" value="{formData.email}" onChange="{handleChange}"></form.control>
                        </form.group>

                        {user.role === "customer" && (
                            <>
                                <form.group controlId="customer-password">
                                    <form.label>Lösenord</form.label>
                                    <div>
                                        <small className="for-example">Lösenordet är redan sparat och du kan gå vidare till nästa steg. Om du vill ändra ditt lösenord anger du ett nytt lösenord i fältet (minst 11 tecken).</small>
                                    </div>
                                    <inputgroup>
                                        <form.control id="new-password" type="{showPass" ?="" "text"="" :="" "password"}="" name="password" value="{formData.password}" onChange="{handleChange}" autoComplete="new-password" style="{{" background:="" "#d7dadd",="" }}=""></form.control>
                                        <button variant="outline-secondary shadow-none show-password-btn" style="{{" background:="" "#d7dadd",="" }}="" onClick="{showPassHandler}">
                                            <i className="{showPass" ?="" "fas="" fa-eye-slash"="" :="" fa-eye"}=""></i>
                                        </button>
                                    </inputgroup>
                                </form.group>
                            </>
                        )}

                        <form.group as="{Row}">
                            <col sm="{6}">
                                <form.label>*Förnamn</form.label>
                                <form.control required="" className="text-field" type="text" name="firstName" placeholder="Etunimi" value="{formData.firstName}" onChange="{handleChange}"></form.control>
                            

                            <col sm="{6}">
                                <form.label>*Efternamn</form.label>
                                <form.control className="text-field" type="text" name="lastName" placeholder="Sukunimi" value="{formData.lastName}" onChange="{handleChange}"></form.control>
                            
                        </form.group>

                        <form.group controlId="customer-mobile-number">
                            <form.label>*Mobiltelefonnummer</form.label>
                            <form.control required="" className="text-field" type="text" name="mobileNumber" placeholder="Matkapuhelinnumero" value="{formData.mobileNumber}" onChange="{handleChange}"></form.control>
                            <small className="for-example">Till exempel +358 50 123456</small>
                        </form.group>

                        <row>
                            <col 6="" md="{isSenior" ?="" :="" 12}="">
                                <form.group>
                                    <form.label>Välj ett av alternativen</form.label>
                                    <form.check className="radio-button" type="radio" label="Olen seniorin läheinen ja ostan palvelun seniorille" id="senior_no" name="isSenior" value="{&quot;false&quot;}" checked="{!isSenior}" onChange="{(e)" ==""> setIsSenior(false)}
                                    />
                                    <form.check className="radio-button" type="radio" label="Olen seniori ja ostan palvelun itselleni" id="senior_yes" name="isSenior" value="{&quot;true&quot;}" checked="{isSenior}" onChange="{(e)" ==""> setIsSenior(true)} /> />.
                                </form.check></form.check></form.group>
                            
                            <col md="{6}">
                                {isSenior && (
                                    <form.group>
                                        <form.label>Seniorboende</form.label>
                                        <form.check className="radio-button" type="radio" label="Koti" id="homeAddress" name="department" value="{&quot;homeAddress&quot;}" checked="{department" =="=" "homeAddress"}="" onChange="{(e)"> setDepartment("hemAddress")}
                                        />
                                        <form.check className="radio-button" type="radio" label="Vanhainkoti" id="nursingHome" name="department" value="{&quot;nursingHome&quot;}" checked="{department" =="=" "nursingHome"}="" onChange="{(e)"> setDepartment("nursingHome")}
                                        />
                                    </form.check></form.check></form.group>
                                )}
                            
                        </row>
                        {isSenior && department === "nursingHome" && (
                            <form.group>
                                <form.group>
                                    <form.label>Äldreboendets namn</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="nursingHome" placeholder="Vanhainkodin nimi" value="{formData.nursingHome}" onChange="{handleChange}"></form.control>
                                </form.group>
                                <form.group>
                                    <form.label>Ort</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="nursingHomeAddress" placeholder="Paikkakunta" value="{formData.nursingHomeAddress}" onChange="{handleChange}"></form.control>
                                </form.group>
                            </form.group>
                        )}

                        {isSenior && department === "homeAddress" && (
                            <div>
                                <form.group>
                                    <form.label>Adress</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="homeAddress" placeholder="Katuosoite" value="{formData.homeAddress}" onChange="{handleChange}"></form.control>
                                </form.group>
                                <form.group>
                                    <form.label>Postnummer</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="homePostalCode" placeholder="Postinumero" value="{formData.homePostalCode}" onChange="{handleChange}"></form.control>
                                </form.group>
                                <form.group>
                                    <form.label>Stad</form.label>
                                    <form.control className="text-field lives-at-home-input" type="text" name="homeCity" placeholder="Kaupunki" value="{formData.homeCity}" onChange="{handleChange}"></form.control>
                                </form.group>
                            </div>
                        )}

                        <row 100="" className="justify-content-md-center" style="{{" minHeight:="" }}="">
                            <col xs="{6}">
                                <button style="{{" float:="" "left",="" }}="" className="tallenna user-form-button" onClick="{back}">
                                    {`◄ Föregående`}
                                </button>
                            
                            <col xs="{6}">
                                <button style="{{" float:="" "right",="" }}="" type="submit" className="tallenna user-form-button">
                                    {isSenior ? `Seuraava Lisää läheiset ►` : `Seuraava Lisää seniori ►`}
                                </button>
                            
                        </row>
                    </form>
                    {fel && <message variant="danger">{fel}</message>}
                    {!error && message &&& <message variant="success">{meddelande}</message>}
                
            </row>
        </container>
    );
};
export default UserForm;
</htmlinputelement>