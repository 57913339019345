import { Col, Container, Row } from "react-bootstrap";

import ContactForm from "../components/ContactForm";
import texts from "../texts";
import { Helmet } from "react-helmet";

// import {Container} from 'react-bootstrap'

const Contact = () => {
    return (
        <>
            <helmet>
                <title>Kontaktinformation | Digihappy</title>
                <meta name="description" content="Metabeskrivning för kontakt: kontakta oss. Vi hjälper dig och dina närstående.">
            </helmet>
            <container fluid="" style="{{" width:="" "100vw",="" backgroundRepeat:="" "no-repeat",="" backgroundImage:="" `url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" connect2.jpg)`,="" backgroundSize:="" "cover",="" backgroundPosition:="" "center",="" position:="" "relative",="" minHeight:="" 640,="" }}="" className="img-fluid contact-pic">
                <row>
                    <col xs="{12}" lg="{7}" className="contact-texts-column">
                        <p className="ota-yhteyttä">{texts[8].text1}</p>
                        <p className="apu">{texts[8].text2}</p>
                    
                    <col xs="{12}" lg="{5}" className="contact-form-column d-lg-block d-none">
                        <contactform></contactform>
                    
                </row>
            </container>
            <section>
                <div className="d-block d-lg-none">
                    <contactform></contactform>
                </div>
            </section>
        </>
    );
};

export default Contact;
