// import { useSelector } from 'react-redux'
import {LinkContainer} from 'react-router-bootstrap'
import {Nav, Navbar} from 'react-bootstrap'
import {useSelector} from "react-redux";
import {AppState} from "../redux/types";

// import { AppState } from '../redux/types'

const SubNavBar = () => {
    const user = useSelector((state: AppState) => state.user)
    // const orders = useSelector((state: AppState) => state.user.orders)
    // const isPaid = orders?.map((o: any) => o.isPaid)
    // isPaid default = false

    return (
        <>
            {user?.id && <navbar expand="lg" collapseOnSelect="" className="subnavbar userpage-nav">
                {/*{isPaid ? (*/}
                {/*  <linkcontainer to="/user">*/}
                {/*    <nav.link className="mr-auto account-link">Kundkonto</nav.link>*/}
                {/*  </linkcontainer>*/}
                {/*) : (*/}
                <nav className="justify-content-left">
                    {/*<linkcontainer to="/video" style="{{color:" '#fff'}}="">*/}
                    {/*    <nav.link className="mr-auto video-link">Videotjänst</nav.link>*/}
                    {/*</linkcontainer>*/}
                    <linkcontainer to="/instructions">
                        <nav.link className="mr-auto health-link">
                        <span style="{{" fontWeight:="" window.location.pathname.includes('="" instructions')="" ?="" 'bold'="" :="" 'unset',="" }}="">
                            <span>
                            Instruktioner
                            </span>
                        </span>
                        </nav.link>
                    </linkcontainer>
                    <linkcontainer to="/tili">
                        <nav.link className="mr-auto account-link">
                        <span style="{{" fontWeight:="" window.location.pathname.includes('="" tili="" ')="" ?="" 'bold'="" :="" 'normal',="" }}="">
                            <span>
                            Kundkonto
                                </span>
                        </span>
                        </nav.link>
                    </linkcontainer>
                </nav>
                {/*)}*/}
            </navbar>}
        </>
    )
}

export standard SubNavBar
