import TextOnImage from "../components/TextOnImage";
import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import celia from "../images/partners/celia.png";
import villa_tapiola from "../images/partners/villa_tapiola.png";
import ainola from "../images/partners/ainola.png";
import loistohoiva from "../images/partners/loistohoiva.png";
import elsa from "../images/partners/elsa.png";
import lehmuskartano from "../images/partners/lehmuskartano.png";
import uvky from "../images/partners/uvky.jpg";
import domacare from "../images/partners/domacare.png";
import helykodit from "../images/partners/helykodit.png";
import hyvan_mielen_kotihoito from "../images/partners/hyvan_mielen_kotihoito.png";
import tunaberg from "../images/partners/tunaberg.jpg";
import linkedin from "../images/linkedin.png";
import aboutus1 from "../images/aboutus1.jpg";

const AboutUs = () => {
    const [video, setVideo] = useState(false);
    const [frameHeight, setFrameHeight] = useState(550);
    const myRef = useRef<htmlheadingelement>(noll);

    returnera (
        <>
            <textonimage height="{`calc(100vh" -="" 66px)`}="" width="100vw" text1="{&quot;Meistä&quot;}" backgroundImage="{&quot;https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/about_us_cover.jpg&quot;}" backgroundSize="cover" backgroundPosition="center center" backgroundRepeat="no-repeat" imgClassName="forcompaniespic" className1="aboutus-text-on-image col-md-8 col-12"></textonimage>
            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Varför Digihappy?
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        En lycklig ålderdom innebär aktiva relationer, meningsfulla aktiviteter och trygghet. Med Digihappys tjänster kan du alltid hålla kontakten med en närstående. Du kan välja en färdig tjänst utifrån den äldres funktionsförmåga och en anhörigs eller vårdares behov.
                        Anhörig eller vårdare behöver inte fungera som IT-stöd.
                    </p>
                </div>
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Titta på videon för att se hur Seniorsurfplattan Trygg gör det lättare för Raili att hålla kontakten med sin dotter som bor i en annan stad. Och hur Asta Martin, föreståndare för Ainola Seniorhotell, har upplevt Digihappy-tjänsten.
                    </p>
                </div>
            </section>
            <section className="texts-for-companies">
                {video ? (
                    <iframe height="{frameHeight}" src="{`https://www.youtube.com/embed/fprEcs7sQKc?autoplay=1&rel=0&color=white&modestbranding=1`}" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" title="Digihappy Seniorsurfplattor" style="{{" margin:="" "0="" auto",="" width:="" "100%",="" }}=""></iframe>
                ) : (
                    <div style="{{" position:="" "relative",="" }}="" ref="{myRef}">
                        <img id="video-cover" src="https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/fprEcs7sQKc_maxresdefault.jpg" alt="Digihappy Seniorsurfplattor" style="{{" height:="" "auto",="" width:="" "100%",="" }}="">
                        <button id="play" className="play-btn" onClick="{()" ==""> {
                                // @ts-ignore
                                setFrameHeight(myRef?.current?.clientHeight);
                                setVideo(true);
                            }}
                        >
                            <i className="fas fa-play" style="{{" color:="" "#ffffff",="" fontSize:="" "6rem",="" }}=""></i>
                        </button>
                    </div>
                )}
            </section>
            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Historien om Digihappy
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy är ett finskt företag som Tiina grundade efter att ha upptäckt hur svårt det var att hålla kontakten med sin far som bodde på ett vårdhem. Tiina är läkare, MD och entreprenör{""}
                        <a className="linkki" href="https://www.linkedin.com/in/tiinaleivomd/?originalSubdomain=fi" target="_blank" rel="noreferrer">
                            <img src="{linkedin}" alt="LinkedIn" style="{{" height:="" "1.5rem",="" marginBottom:="" "0.2rem",="" }}="">
                        </a>
                        , som har samlat ett skickligt kärnteam och begåvade finländska och internationella partners. Vi levererar tjänster till seniorer, familjer och vårdgivare. Vårt kontor ligger i Otnäs, Esbo.
                    </p>
                </div>
            </section>

            <section className="" style="{{" margin:="" "2rem",="" }}="">
                <row className="align-items-end justify-content-center">
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" Tiina%2C+new.png)",="" }}="" className="aboutus-photo-img-1"></div>
                        </div>
                        <div className="aboutus-photo-caption">
                            <b>Tiina</b>
                           <br/>
                            Grundare av Digihappy
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" johanna.jpeg)",="" }}="" className="aboutus-photo-img-1"></div>
                        </div>
                        <div className="aboutus-photo-caption">
                            <b>Johanna</b>
                           <br/>
                            UX, COO, kundupplevelse och verksamhet
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box" xl="4" lg="6" md="6">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" `url(${aboutus1})`,="" width:="" "100%",="" }}="" className="aboutus-photo-img-1"></div>
                        </div>
                        <div className="aboutus-photo-caption">CTO Joel, Johanna och Tiina</div>
                    
                </row>
                <row className="align-items-end justify-content-center">
                    <col className="col-md-auto aboutus-photo-box" xl="4" lg="6" md="6">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url('="" assets="" images="" about="" digihappy-team.webp')",="" width:="" "100%",="" }}="" className="aboutus-photo-img-2" aria-label="Kolme ihmistä kypärät päässä ajamassa polkupyörillä järven rannalla."></div>
                        </div>
                        <div className="aboutus-photo-caption" style="{{" maxWidth:="" "100%"="" }}="">
                            Digihappy på mountainbike Till höger Rebecka Svensktalande service- och sociala-medier-ansvarig
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url('="" assets="" images="" about="" digihappy-programmer.webp')",="" }}="" className="aboutus-photo-img-1" aria-label="Mies harmaassa paidassa istuu kivellä veden äärellä"></div>
                        </div>
                        <div className="aboutus-photo-caption">
                            Brian
                           <br/>
                            Devops
                        </div>
                    
                    <col className="col-md-auto aboutus-photo-box">
                        <div className="aboutus-photo">
                            <div style="{{" backgroundImage:="" "url(https:="" senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com="" Creole.png)",="" }}="" className="aboutus-photo-img-2"></div>
                        </div>
                        <div className="aboutus-photo-caption">Creole studios, Pavle A, Peter, Orest Full-stack utveckling</div>
                    
                </row>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Digihappy är finalist i tävlingen Årets digitala aktör
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy valittiin vuoden Digitaalinen teko 2022 finaaliin kolmen parhaan joukkoon. Tuomaristo perusteli Digihappyn valintaa seuraavasti: ”Suomi ikääntyy ja vanhukset ovat usein heikoimmilla digitalisaatiossa.
                        Digihappy on kehitetty juuri ikäihmisten lähtökohdista ja räätälöity heidän tarpeisiinsa. Yhteydenpitopalvelun kokonaisuus laitteineen ja ohjelmistoineen sekä sen käytön helppous ja selkeys vakuuttavat.” Lue lisää{" "}
                        <a target="_blank" className="linkki" rel="noreferrer" href="https://www.atea.fi/vuoden-digitaalisin-teko-2022/">
                            https://www.atea.fi/vuoden-digitaalisin-teko-2022/
                        </a>
                    </p>
                </div>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Ansvar handlar om hållbara produkter, kundfokus och datasäkerhet
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        För oss innebär ansvarsfullhet att vi endast använder utrustning och tillbehör av hög kvalitet som har lång livslängd och som kan underhållas och återvinnas. Vi säljer inte direkt till en äldre person som inte förstår vad han eller hon köper.
                        vad de köper. Vi binder inte upp kunden med långa kontrakt. Datasäkerhet är genuint viktigt för oss i allt vi gör. Telefonförsäljare kan inte göra Digihappy videosamtal. till en surfplatta. Och vårt mål är att göra det
                        kostnadseffektiva tjänster så att så många som möjligt kan få det.
                    </p>
                </div>
            </section>

            <h2 id="products" className="subscribe-header subscribe-header-blue">
                Samarbete är viktigt för oss
            </h2>
            <section className="texts-for-companies">
                <div className="text-div-for-companies">
                    <p className="text-for-companies-first">
                        Digihappy samarbetar med vårdgivare, offentliga aktörer och föreningar. Vi utvecklar tjänster med hänsyn till användarnas önskemål. Vi har djup expertis inom forskning, vilket vi också gör tillsammans
                        med våra samarbetspartners. Vi är tacksamma för möjligheten att få arbeta med många banbrytande organisationer.
                    </p>
                </div>
            </section>

            <section className="texts-for-companies">
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://villatapiola.fi/villatapiola/index.php" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{villa_tapiola}" alt="Villa Tapiola" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://villatapiola.fi/ainola/ainola/index.php" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{ainola}" alt="Ainola Seniorihotelli" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://loistohoiva.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{loistohoiva}" alt="Härliga utomhusaktiviteter" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://hoivakodit.luvn.fi/fi-FI/hoivakodit/d19f24f84b" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{lehmuskartano}" alt="Lehmuskartano" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.celia.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{celia}" alt="Celia" className="aboutus-partners">
                        </a>
                    
                </row>
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.uvky.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{uvky}" alt="UVKY" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://domacare.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{domacare}" alt="Domacare" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.helykodit.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{helykodit}" alt="Helykoder" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://hyvanmielenkotihoito.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{hyvan_mielen_kotihoito}" alt="Hyvän Mielen Kotihoito" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.elsa.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{elsa}" alt="Elsa" className="aboutus-partners">
                        </a>
                    
                </row>
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.hoitajasi.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/hoitajasi.png&quot;}" alt="Din vårdgivare" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.hoivapalveluanne.com/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/hoivapalveluanne.webp&quot;}" alt="Din omsorgstjänst" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.seniorhem.fi/tunaberg-seniorhem-och-tunaro-vardhem/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{tunaberg}" alt="Tunaberg seniorhem" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://posifon.se/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/posifon.png&quot;}" alt="Posifon" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.sanitum.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/Sanitum.jpg&quot;}" alt="Sanitum" className="aboutus-partners">
                        </a>
                    
                </row>
                <row className="align-items-baseline justify-content-around">
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.omegayksi.fi/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/omegayksi.png&quot;}" alt="Omegayksi" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://satakunnanhyvinvointialue.fi/palvelut/ikaantyneet/kotona-asumisen-tukipalvelut/funteeraamo/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="{&quot;/assets/images/about/logos/Funteeraamo.png&quot;}" alt="Funteeraamo" className="aboutus-partners">
                        </a>
                    
                    <col className="col-md-2 col-4 aboutus-partners-col">
                        <a href="https://www.jomala.ax/" className="linkki" target="_blank" rel="noreferrer">
                            <img src="/assets/images/about/logos/jomala.png" alt="Jomala" className="aboutus-partners">
                        </a>
                    
                </row>
            </section>
        </>
    );
};

export default AboutUs;
</htmlheadingelement>