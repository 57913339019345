import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Col, Container, Form, Row} from 'react-bootstrap'

import {updateUserRequest,} from '../redux/actions'
import Message from './Message'
import Loader from './Loader'
import {AppState} from '../redux/types'

const MemberForm = ({onSelect, key}: any) => {
    const member = useSelector((state: AppState) => state.user)
    const {error, loading} = member;
    const [chosenService, setChosenService] = useState(member?.chosenService || '');
    const [serviceLang, setServiceLang] = useState(member?.tabletLanguage || '');
    const [autoAnswer, setAutoAnswer] = useState(member?.autoAnswer || '');
    const [option, setOption] = useState(member?.serviceOption || '');
    const relativeDoneEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeAccountDone);

    const dispatch = useDispatch()

    useEffect(() => {
        console.log(member?.chosenService)
        console.log(chosenService)
        if (!member?.chosenService || member?.chosenService !== chosenService) {
            if (chosenService === 'huoleton') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('huoleton1');
            } else if (chosenService === 'helppo') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('helppo4');
            } else if (chosenService === 'mestari') {
                setServiceLang('suomi');
                setAutoAnswer('false');
            } else if (chosenService === 'suosikkipalvelu') {
                setServiceLang('suomi');
                setAutoAnswer('true');
                setOption('helppo4');
            } else if (chosenService === 'peruspalvelu') {
                setServiceLang('suomi');
                setAutoAnswer('true');
            }
        } else {
            setServiceLang(member?.tabletLanguage);
            setAutoAnswer(member?.autoAnswer);
        }
    }, [chosenService, member])

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        dispatch(updateUserRequest({
            ...member,
            password: '',
            chosenService: chosenService,
            tabletLanguage: serviceLang,
            autoAnswer: autoAnswer,
            serviceOption: option,
            relativeDoneEmailId
        }));

        setTimeout(function () {
            onSelect('tilaus');
        }, 3000);
    }

    const auto_answer = <form.group className="feature_choices-form">
        <label>Starta ett videosamtal på seniorens surfplatta när en familjemedlem eller skötare ringer:</label>
        <div style="{{" marginLeft:="" 127,="" }}="">
            <form.check className="radio-button" type="radio" label="{`Videopuhelu" avautuu="" automaattisesti="" ilman="" kosketusta${chosenService="==" 'mestari'="" ?="" '="" (mahdollinen="" Samsung="" tabletissa)'="" :="" ''}`}="" id="true" name="autoAnswer" value="{'true'}" checked="{autoAnswer" =="=" 'true'}="" onChange="{()"> setAutoAnswer('true')}
            />
            <form.check className="radio-button" type="radio" label="{`Videopuhelu" ei="" avaudu="" automaattisesti="" ilman="" kosketusta${chosenService="==" 'mestari'="" ?="" '="" (Samsung="" tai="" Apple)'="" :="" ''}`}="" id="false" name="autoAnswer" value="{'false'}" checked="{autoAnswer" =="=" 'false'}="" onChange="{()"> setAutoAnswer('false')}
            />
        </form.check></form.check></div>
    </form.group>

    const lang = <form.group className="feature_choices-form">
        <label>Surfplattans språk:</label>
        <form.check style="{{" display:="" 'inline-block',="" margin:="" 8,="" }}="" className="radio-button" type="radio" label="Suomi" id="suomi" name="serviceLang" value="{'suomi'}" checked="{serviceLang" =="=" 'suomi'}="" onChange="{()"> setServiceLang('suomi')}
        />
        <form.check style="{{" display:="" 'inline-block',="" margin:="" 8,="" }}="" className="radio-button" type="radio" label="Ruotsi" id="ruotsi" name="serviceLang" value="{'ruotsi'}" checked="{serviceLang" =="=" 'ruotsi'}="" onChange="{()"> setServiceLang('swedish')}
        />
    </form.check></form.check></form.group>

    const helppo_options = helppo_options <form.group className="feature_choices-form">
        <label>Utbud av appar:</label>
        <div style="{{" marginLeft:="" 127,="" }}="">
            <form.check className="radio-button" type="radio" label="Videopuhelu" id="helppo1" name="option" value="{'helppo1'}" checked="{option" =="=" 'helppo1'}="" onChange="{()"> setOption('easy1')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio" id="helppo2" name="option" value="{'helppo2'}" checked="{option" =="=" 'helppo2'}="" onChange="{()"> setOption('easy2')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet" id="helppo3" name="option" value="{'helppo3'}" checked="{option" =="=" 'helppo3'}="" onChange="{()"> setOption('easy3')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit" id="helppo4" name="option" value="{'helppo4'}" checked="{option" =="=" 'helppo4'}="" onChange="{()"> setOption('easy4')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, taide" id="helppo5" name="option" value="{'helppo5'}" checked="{option" =="=" 'helppo5'}="" onChange="{()"> setOption('easy5')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit, taide, Spotify" id="helppo6" name="option" value="{'helppo6'}" checked="{option" =="=" 'helppo6'}="" onChange="{()"> setOption('easy6')}
            />
            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja, radio, Yle Areena, lehdet, pelit, taide, Spotify, Whatsapp" id="helppo7" name="option" value="{'helppo7'}" checked="{option" =="=" 'helppo7'}="" onChange="{()"> setOption('easy7')}
            />
        </form.check></form.check></form.check></form.check></form.check></form.check></form.check></div>
    </form.group>

    Återgå (
        <container fluid="">
            <row className="justify-content-md-left">
                <col xs="{12}">
                    {fel && <message variant="danger">{error.message}</message>}
                    {Laddning && <loader></loader>}
                    <h1 className="member-form-header">4 Anpassade funktioner</h1>
                    {member?.id && <div className="feature_choices">
                        <div className="text-for-companies-second">
                            Vi rekommenderar att du fyller i det här avsnittet, men det är inte obligatoriskt. Standardinställningarna är följande
                            Finska, automatisk öppning av videosamtalet och den valda tjänsten (Careless, Easy,
                            Master), det mest populära valet av program. Fortsätt genom att välja den tjänst du har köpt eller gå till
                            gå vidare.
                        </div>
                        <form onSubmit="{submitHandler}">
                            <form.group className="feature_choices">
                                <form.check className="radio-button" type="radio" label="Huoleton - palvelu" id="huoleton" name="chosenService" value="huoleton" checked="{chosenService" =="=" 'huoleton'}="" onChange="{()"> setChosenService('huoleton')}
                                />
                                {chosenService === 'carefree' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    <form.group className="feature_choices-form">
                                        <label>Utbud av appar:</label>
                                        <div style="{{" marginLeft:="" 127,="" }}="">
                                            <form.check className="radio-button" type="radio" label="Videopuhelu" id="huoleton1" name="option" value="{'huoleton1'}" checked="{option" =="=" 'huoleton1'}="" onChange="{()"> setOption('huoleton1')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, radio" id="huoleton2" name="option" value="{'huoleton2'}" checked="{option" =="=" 'huoleton2'}="" onChange="{()"> setOption('huoleton2')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, äänikirja" id="huoleton3" name="option" value="{'huoleton3'}" checked="{option" =="=" 'huoleton3'}="" onChange="{()"> setOption('huoleton3')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, Yle Areena" id="huoleton4" name="option" value="{'huoleton4'}" checked="{option" =="=" 'huoleton4'}="" onChange="{()"> setOption('huoleton4')}
                                            />
                                            <form.check className="radio-button" type="radio" label="Videopuhelu, radio, äänikirja, Yle Areena" id="huoleton5" name="option" value="{'huoleton5'}" checked="{option" =="=" 'huoleton5'}="" onChange="{()"> setOption('huoleton5')}
                                            />
                                        </form.check></form.check></form.check></form.check></form.check></div>
                                    </form.group>
                                    <div className="feature_choices-text">
                                        <p>
                                            En bekymmersfri surfplatta är den minsta och mest prisvärda. Vi rekommenderar den endast för
                                            tillämpningar där du inte behöver skriva på surfplattans tangentbord.
                                            Vi väljer noggrant ut applikationer för surfplattan som är populära och lätta att använda,
                                            säkra och gratis. Vi utvecklar och utökar ständigt vårt utbud
                                            och gör ändringar vid behov.
                                        </p>
                                        <p>
                                            Den kostnadsfria ljudboksappen produceras av Celia. Vi ger råd om hur du får tillgång till tjänsten och hur du får inloggningsuppgifter från biblioteket. Tjänsten är avsedd för äldre personer med t.ex. nedsatt syn, muskelkondition, funktion eller minne. 
                                        </p>
                                    </div>
                                </div>}
                                <form.check className="radio-button" type="radio" label="Helppo - palvelu" id="helppo" name="chosenService" value="helppo" checked="{chosenService" =="=" 'helppo'}="" onChange="{()"> setChosenService('lätt')}
                                />
                                {chosenService === 'lätt' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    {helppo_options}
                                    <div className="feature_choices-text">
                                        <p>
                                            Vi väljer noggrant ut appar för din surfplatta som är populära, enkla att använda,
                                            säkra och kostnadsfria. Exempel på spel är patiens, memoryspel och schack
                                            schack och tidningarna HS och IS. Vi utvecklar ständigt vårt sortiment och gör
                                            på dem
                                            och gör ändringar där det behövs.
                                        </p>
                                        <p>
                                            Den kostnadsfria ljudboksappen produceras av Celia. Vi ger råd om hur du får tillgång till tjänsten och hur du får inloggningsuppgifter från biblioteket. Tjänsten är avsedd för äldre personer med t.ex. nedsatt syn, muskelkondition, funktion eller minne. Om senioren vill ha en enskild tilläggsapp eller en betalversion av någon app hjälper vår IT-stöd till. Senioren kan inte själv lägga till appar på surfplattan Trygg eller Enkel.
                                        </p>
                                    </div>
                                </div>}
                                <form.check className="radio-button" type="radio" label="Mestari - palvelu" id="mestari" name="chosenService" value="mestari" checked="{chosenService" =="=" 'mestari'}="" onChange="{()"> setChosenService('master')}
                                />
                                {chosenService === 'mestari' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    <div className="feature_choices-text">
                                        <p>
                                            </p><div>
                                                <b>Apputbudet på surfplattan</b>
                                            </div>
                                            Surfplattan Mästares appar har valts ut med omsorg och installerats färdigt för senioren. Surfplattan har färdigt bl.a. Digihappy
                                            videosamtal, Celias gratis ljudböcker, Chrome, radio, kamera, Yle
                                            Arenan, tidningar som HS och HBL, spel som solitaire, memoryspel och schack,
                                            konst, Spotify, Whatsapp, Facebook, Gmail och andra Google-appar.
                                            Vi utvecklar ständigt vårt utbud och kommer att göra ändringar vid behov.
                                            I en personlig inledande handledning installerar tilläggsappar enligt seniorens önskningar och ger råd för hur du skapar personliga konton för e-post och appar. Vi ger också råd för hur seniorer kan ladda ner appar själva.
                                        <p></p>
                                        <p>
                                            Den kostnadsfria ljudboksappen produceras av Celia. Vi ger råd om hur du får tillgång till tjänsten och hur du får inloggningsuppgifter från biblioteket. Tjänsten är avsedd för äldre personer med t.ex. nedsatt syn, muskelkondition, funktion eller minne. 
                                        </p>
                                    </div>
                                </div>}
                                <form.check className="radio-button" type="radio" label="Kotisairaanhoito/Palveluasunto Suosikkipalvelu" id="suosikkipalvelu" name="chosenService" value="suosikkipalvelu" checked="{chosenService" =="=" 'suosikkipalvelu'}="" onChange="{()"> setChosenService('favorit tjänst')}
                                />
                                {chosenService === 'favorittjänst' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                    {helppo_options}
                                </div>}
                                <form.check className="radio-button" type="radio" label="Kotisairaanhoito/Palveluasunto Peruspalvelu" id="peruspalvelu" name="chosenService" value="peruspalvelu" checked="{chosenService" =="=" 'peruspalvelu'}="" onChange="{()"> setChosenService('grundläggande tjänst')}
                                />
                                {chosenService === 'grundläggande tjänst' && <div className="feature_choices-option">
                                    {lang}
                                    {auto_response}
                                </div>}
                            </form.check></form.check></form.check></form.check></form.check></form.group>
                        </form>
                        <div className="feature_choices-text">
                            Om du enbart beställer appen Digihappy videosamtal på seniorens egen eller sjukhusets delade enhet, gäller detta avsnitt inte dig.
                        </div>

                        <button type="button" className="tallenna" onClick="{submitHandler}">Klar</button>
                    </div>}
                
            </row>
        </container>
    )
}
export standard MemberForm
