import { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import api from "../utils/api-helper/api";
import FormContainer from "../components/FormContainer";

class RecoverPassword extends Component {
    state = {
        username: "",
        submitted: false,
    };

    handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
        this.setState({ username: e.target.value });
    };

    sendPasswordResetEmail = (e: React.FormEvent) => {
        e.preventDefault();
        const { username } = this.state;
        console.log("click");
        console.log("email", username);
        api.post(`/email/reset-password/user/${username}`);
        this.setState({ username: "", submitted: true });
    };
    render() {
        const { username, submitted } = this.state;
        return (
            <formcontainer>
                <h2 className="my-4 text-center">Ändra lösenord</h2>
                {sändes ? (
                    <div className="reset-password-form">
                        <p>Om ditt konto finns i vårt system skickar vi ett e-postmeddelande till dig med en länk för att ändra ditt lösenord.</p>
                        <link to="/kirjaudu" className="tallenna">
                            Logga in
                        
                    </div>
                ) : (
                    <div className="reset-password-form-wrapper">
                        <p className="my-3">Ange din e-postadress så skickar vi instruktioner om hur du ändrar ditt lösenord.</p>
                        <form onSubmit="{this.sendPasswordResetEmail}">
                            <form.label htmlFor="email" className="form-label">
                                E-postadress
                            </form.label>
                            <form.group controlId="recover-password-email">
                                <form.control required="" onChange="{this.handleChange}" value="{username}" placeholder="Sähköpostiosoite"></form.control>
                            </form.group>
                            <button className="password-reset-btn tallenna" type="submit" style="{{" width:="" "100%"="" }}="">
                                Skicka en begäran om ändring av lösenord
                            </button>
                        </form>
                        <link to="/kirjaudu" className="muistan-salasanan">
                            Jag kommer ihåg mitt lösenord
                        
                    </div>
                )}
            </formcontainer>
        );
    }
}
export default RecoverPassword;
</htmlinputelement>