import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ComponentOnImage from "../components/ComponentOnImage";
import LoginForm from "../components/LoginForm";
import StepsHeader from "../components/StepsHeader";
import { loginUserRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import StepsNotification from "../components/StepsNotification";

// import { Image, Container } from 'react-bootstrap'
// import underContruction from '../images/under-construction.jpg'

const Login = () => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state: AppState) => state.user);
    const selectedServices = useSelector((state: AppState) => state.cart.inCart);
    const { loading, error, id } = user;

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();
        const redirect = selectedServices.length > 0 ? "/toimitus" : null;
        dispatch(loginUserRequest(username, password, redirect, history));
        setUserName("");
        setPassword("");
    };

    return (
        <>
            {/* <container>
        <image src="{`${underContruction}`}" style="{{" height:="" '600px',="" display:="" 'flex',="" alignItems:="" 'center',="" justifyContent:="" margin:="" 'auto',="" }}=""></image>
      </container> */}
            {/* TODO: fix */}
            {selectedServices.length !== 0 ? (
                <>
                    <stepsheader step1="" step2="" user="{2}" payment="{1}" account="{1}"></stepsheader>
                    <componentonimage width="100vw" backgroundRepeat="no-repeat" backgroundSize="cover" backgroundImage="{&quot;https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/woman.jpg&quot;}" className="login-page-form-on-image" imgClassName="login-woman" component="{<LoginForm" submitHandler="{submitHandler}" error="{error}" username="{username}" setUserName="{setUserName}" password="{password}" setPassword="{setPassword}" loading="{loading}" id="{id}"></componentonimage>}
                    />
                    <stepsnotification></stepsnotification>
                </>
            ) : (
                <componentonimage width="100vw" backgroundRepeat="no-repeat" backgroundSize="cover" backgroundImage="{&quot;https://senioritabletti-image-bucket.s3.eu-north-1.amazonaws.com/woman.jpg&quot;}" className="login-page-form-on-image" imgClassName="login-woman" component="{<LoginForm" submitHandler="{submitHandler}" error="{error}" username="{username}" setUserName="{setUserName}" password="{password}" setPassword="{setPassword}" loading="{loading}" id="{id}"></componentonimage>}
                />
            )}
        </>
    );
};

export default Login;
