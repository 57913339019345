const ServicesAccordionTable = () => {
    returnera (
        <div className="palvelut-table-div">
            <table className="palvelut-table">
                <colgroup>
                    <col style="{{" width:="" "40%",="" }}="">
                    <col style="{{" width:="" "20%",="" }}="">
                    <col style="{{" width:="" "20%",="" }}="">
                    <col style="{{" width:="" "20%",="" }}="">
                </colgroup>
                <tbody>
                    <tr>
                        <td></td>
                        <td>
                            <h1 className="accordion-button-header">Trygg</h1>
                            <div className="accordion-button-text">när det är svårt att använda en surfplatta och du främst vill ringa videosamtal. Billigast och minst.</div>
                        </td>
                        <td>
                            <h1 className="accordion-button-header">Enkel</h1>
                            <div className="accordion-button-text">när användning av en surfplatta är utmanande, men i viss mån är möjligt. Populärast.</div>
                        </td>
                        <td>
                            <h1 className="accordion-button-header">Mästare</h1>
                            <div className="accordion-button-text">när det är enkelt att använda en surfplatta. För den aktiva senioren.</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Storlek</td>
                        <td>8,7”</td>
                        <td>10,5" / 11,0"</td>
                        <td>10,5" / 11,0"</td>
                    </tr>
                    <tr>
                        <td>Förinstallerade appar på surfplattan</td>
                        <td>Endast Digihappy videosamtal, alternativt även radioapparater, ljudböcker</td>
                        <td>Endast Digihappy videosamtal eller ditt urval av radio, ljudböcker, Chrome, YleAreena, tidskrifter, patiens, memoryspel, schack, konst, medicin, Whatsapp</td>
                        <td>Omfattande utbud av appar</td>
                    </tr>
                    <tr>
                        <td>Du kan lägga till dina egna appar</td>
                        <td></td>
                        <td>
                            <i className="icon fas fa-check-circle fa-4x"></i>
                        </td>
                        <td>
                            <i className="icon fas fa-check-circle fa-4x"></i> <i className="icon fas fa-check-circle fa-4x"></i>
                        </td>
                    </tr>
                    <tr>
                        <td>Hemskärm</td>
                        <td>Anpassad för att underlätta användningen</td>
                        <td>Anpassad för att underlätta användningen</td>
                        <td>Samsung-vy</td>
                    </tr>
                    <tr>
                        <td>Surfplatta</td>
                        <td>Samsung Galaxy Tab A7 Lite LTE/A9 5G</td>
                        <td>Samsung Galaxy Tab A8 lte/A9+ 5G</td>
                        <td>Samsung Galaxy Tab A8 lte/A9+ 5G</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ServicesAccordionTable;
