import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/types";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import api from "../utils/api-helper/api";
import { Helmet } from "react-helmet";
import texts from "../texts";
import TextOnImage from "../components/TextOnImage";
import faq_photo from "../images/faq_photo.jpg";

const FAQPage = () => {
    const page_id = "faq_page";
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [edit, setEdit] = useState(false);
    const [content, setContent] = useState("");
    const [page, setPage] = useState({});
    console.log(user);
    useEffect(() => {
        api.get(`/page/public/${page_id}`)
            .then((resp: any) => {
                console.log(resp);
                setPage(resp.data.payload);
                setContent(resp.data.payload?.content);
            })
            .catch((error) => {
                const body = error.response.data;
                console.log(body);
                setPage({});
                setContent("");
            })
            .finally(() => {});
        return () => {};
    }, [dispatch, history]);

    const savePage = () => {
        api.post(`/page`, {
            ...page,
            route: page_id,
            content: content,
            public: true,
        })
            .then((resp: any) => {
                console.log(resp);
                setPage(resp.data.payload);
                setContent(resp.data.payload?.content);
                setEdit(false);
            })
            .catch((error) => {
                const body = error.response.data;
                console.log(body);
            })
            .finally(() => {});
    };

    return (
        <>
            <helmet>
                <title>Vanliga frågor och svar. Läs mer på engelska.</title>
                <meta name="description" content="Vanliga frågor och svar. Köpa och beställa tjänster. Ta reda på mer om tjänster.">
            </helmet>
            <textonimage height="{`calc(100vh" -="" 66px)`}="" width="100vw" text1="{texts[12].text1}" text2="{texts[12].text2}" text21="{texts[12].text21}" text22="{texts[12].text22}" backgroundImage="{faq_photo}" backgroundSize="cover" backgroundPosition="center center" backgroundRepeat="no-repeat" imgClassName="forcompaniespic" className1="faq-text-on-image col-md-4 col-12" className2="faq-text-on-image col-md-4 col-12 faq-text-on-image-second"></textonimage>
            <container fluid="">
                <row className="justify-content-md-left" data-color-mode="light">
                    <col xs="{12}">
                        {edit ? (
                            <mdeditor value="{content}" @ts-ignore="" onChange="{setContent}"></mdeditor>
                        ) : (
                            <mdeditor.markdown source="{content}"></mdeditor.markdown>
                        )}
                    
                    {user && user?.isAdmin && !user?.readOnly && (
                        <col xs="{12}" style="{{" textAlign:="" "right",="" }}="">
                            {!edit && (
                                <button className="create-service-button tallenna" type="submit" onClick="{()" ==""> setEdit(true)}>
                                    Redigera
                                </button>
                            )}

                            {redigera && (
                                <button className="create-service-button tallenna" type="submit" onClick="{()" ==""> setEdit(false)}>
                                    Avbryt
                                </button>
                            )}

                            {redigera && (
                                <button className="create-service-button tallenna" type="submit" onClick="{()" ==""> savePage()}>
                                    Spara
                                </button>
                            )}
                        
                    )}
                </row>
            </container>
        </>
    );
};

export default FAQPage;
