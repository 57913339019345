import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteServiceRequest, getServicesRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const ServicesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getServicesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const resources = useSelector((state: AppState) => state.resources);
  const { services, loading, error } = resources;

  const deleteHandler = (id: any) => {
    if (window.confirm("Haluatko varmasti poistaa palvelun?")) {
      dispatch(deleteServiceRequest(id));
    }
  };

  const addNewService = () => {
    history.push("/admin/service/new");
  };

  return (
    <>
      <h1 className="my-3 ml-3" style="{{" color:="" "#4e5180",="" fontFamily:="" "Poppins,="" sanf-serif"="" }}="">
        Tjänster
      </h1>
      {user.isAdmin && (
        <button onClick="{addNewService}">Lägg till en ny tjänst</button>
      )}
      {laddning ? (
        <loader></loader>
      ) : error ? (
        <message variant="danger">{fel}</message>
      ) : (
        <table striped="" bordered="" hover="" responsive="" className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">Sortera efter antal</th>
              <th className="text-center">NAMN</th>
              <th className="text-center">BESKRIVNING I VÄNSTER KOLUMN</th>
              <th className="text-center">BESKRIVNING HÖGER KOLUMN</th>
              <th className="text-center">PRIS</th>
              <th className="text-center">KATEGORI</th>
              <th className="text-center">Aktiv</th>
              <th className="text-center">Stripe produkt-ID</th>
              <th className="text-center">
                {user.readOnly || !user.isAdmin ? "Näkymä" : "Päivitä /Poista"}
              </th>
            </tr>
          </thead>
          <tbody>
            {services &&
              services.map((service: any) => (
                <tr key="{service.id}">
                  <td>{service.id}</td>
                  <td>{service.sortNumber}</td>
                  <td>{service.name}</td>
                  <td>{service.descriptionFirst}</td>
                  <td>{service.descriptionSecond}</td>
                  <td>{service.price}</td>
                  <td>{service.category}</td>
                  <td>
                    {service.isActive ? (
                      <i className="fas fa-check" style="{{" color:="" "#4e5180"="" }}=""></i>
                    ) : (
                      <i className="fas fa-times" style="{{" color:="" "red"="" }}=""></i>
                    )}
                  </td>
                  <td>
                    {service.category !== "shipping" && (
                      <div>{service.stripeProductId || "none"}</div>
                    )}
                    {service.category === "shipping" && (
                      <div>
                        <div title="betalda">
                          <i 4="" className="fas fa-euro-sign" style="{{" display:="" "inline",="" paddingRight:="" }}=""></i>
                          {service.stripeShippingRateId || "none"}
                        </div>
                        <hr>
                        <div title="gratis">
                          <i 4="" className="fas fa-gift" style="{{" display:="" "inline",="" paddingRight:="" }}=""></i>
                          {service.stripeShippingRateIdFree || "none"}
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    <linkcontainer to="{`/admin/service/${service.id}`}">
                      <button className="btn-sm">
                        <i className="{" user.readOnly="" ||="" !user.isAdmin="" ?="" "fas="" fa-eye"="" :="" fa-edit"="" }="" style="{{" color:="" "#fff"="" }}=""></i>
                      </button>
                    </linkcontainer>
                    {!user.readOnly && user.isAdmin && (
                      <button variant="danger" className="btn-sm" onClick="{()" ==""> deleteHandler(service.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export standard ServicesList;
