import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  deleteSeniorHomeRequest,
  getSeniorHomesRequest,
} from "../redux/actions";
import { AppState } from "../redux/types";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const SeniorHomesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getSeniorHomesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const resources = useSelector((state: AppState) => state.seniorHomes);
  const { list, loading, deleted_at, error } = resources;

  const deleteHandler = (id: any) => {
    if (window.confirm("Haluatko varmasti poistaa hälytyksen?")) {
      dispatch(deleteSeniorHomeRequest(id));
    }
  };

  const addNewService = () => {
    history.push("/admin/senior_home/new");
  };

  useEffect(() => {
    dispatch(getSeniorHomesRequest());
  }, [dispatch, deleted_at]);

  return (
    <>
      <h1 className="my-3 ml-3" style="{{" color:="" "#4e5180",="" fontFamily:="" "Poppins,="" sanf-serif"="" }}="">
        Äldreboenden
      </h1>
      {user.isAdmin && (
        <button onClick="{addNewService}">Lägg till ett nytt äldreboende</button>
      )}
      {laddning ? (
        <loader></loader>
      ) : error ? (
        <message variant="danger">{fel}</message>
      ) : (
        <table striped="" bordered="" hover="" responsive="" className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">NAMN</th>
              <th className="text-center">REFERENS</th>
              <th className="text-center">VISA</th>
              <th className="text-center">
                {user.readOnly || !user.isAdmin ? "Näkymä" : "Päivitä /Poista"}
              </th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((senior_home: any) => (
                <tr key="{senior_home.id}">
                  <td>{senior_home.id}</td>
                  <td>{senior_home.name}</td>
                  <td>{senior_home.reference}</td>
                  <td>
                    {senior_home.show ? (
                      <i className="fas fa-check" style="{{" color:="" "#4e5180"="" }}=""></i>
                    ) : (
                      <i className="fas fa-times" style="{{" color:="" "red"="" }}=""></i>
                    )}
                  </td>
                  <td>
                    <linkcontainer to="{`/admin/senior_home/${senior_home.id}`}">
                      <button className="btn-sm">
                        <i className="{" user.readOnly="" ||="" !user.isAdmin="" ?="" "fas="" fa-eye"="" :="" fa-edit"="" }="" style="{{" color:="" "#fff"="" }}=""></i>
                      </button>
                    </linkcontainer>
                    {!user.readOnly && user.isAdmin && (
                      <button variant="danger" className="btn-sm" onClick="{()" ==""> deleteHandler(senior_home.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export standard SeniorHomesList;
