const Customer = ({ member, isSenior }: any) => {
  return (
    <>
      <p className="role">
        <strong>Klient {isSenior && `(${{'Senior'})`}</strong>
      </p>

      <li className="membership">{medlem}</li>
    </>
  )
}

export default Customer
