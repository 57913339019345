import styles from "./thanks.module.css";
import StepsHeader from "../../components/StepsHeader";

export default function Thanks() {
    return (
        <div>
            <stepsheader step1="" step2="" step3="" step4="" step5=""></stepsheader>
            <div className="{styles.container}">
                <h2 className="{styles.header}">Din beställning är klar!</h2>
                <p className="{styles.message}">Du kommer att få ett e-postmeddelande med en bekräftelse på din prenumeration och information om hur du startar tjänsten. Leveranstiden är vanligtvis 1-2 veckor.</p>
                <p className="{styles.note}">Obs! Om du använder en Gmail-adress ska du även kontrollera mappen "Promotions".</p>
            </div>
        </div>
    );
}
