import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteOrderRequest, getOrdersRequest } from "../redux/actions";
import { AppState } from "../redux/types";

const Orders = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    useEffect(() => {
        if (user && (user.isAdmin || user.role === "it_support")) {
            dispatch(getOrdersRequest());
        } else {
            history.push("/kirjaudu");
        }
        return () => {};
    }, [dispatch, user, history]);

    let orders = useSelector((state: AppState) => state.orders);
    const { loading, error, list } = orders;

    const deleteHandler = (id: any) => {
        if (window.confirm("Haluatko varmasti poistaa tilauksen?")) {
            dispatch(deleteOrderRequest(id));
        }
    };

    return (
        <>
            <h1 className="my-3 ml-3" style="{{" color:="" "#4e5180",="" fontFamily:="" "Poppins,="" sanf-serif"="" }}="">
                Beställningar
            </h1>
            {Lastning ? (
                <loader></loader>
            ) : error ? (
                <message variant="danger">{fel}</message>
            ) : (
                <table striped="" bordered="" hover="" responsive="" className="users-table">
                    <thead className="user-title">
                        <tr className="user">
                            <th className="text-center">ID</th>
                            <th className="text-center">Namn</th>
                            <th className="text-center">Adress</th>
                            <th className="text-center">Leveransmetod</th>
                            <th className="text-center">ÄR BETALD</th>
                            <th className="text-center">Betalningsmetod</th>
                            <th className="text-center">Pris</th>
                            <th className="text-center">Försändelse</th>
                            <th className="text-center">Kupong</th>
                            <th className="text-center">Skapad</th>
                            <th className="text-center">Uppdaterad</th>
                            {!user.readOnly && user.isAdmin && <th className="text-center">Ta bort från</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((order: any) => (
                            <tr key="{order.id}">
                                <td>{order.id}</td>
                                <td>
                                    {order.namn}
                                   <br/>
                                    {order.e-post}
                                   <br/>
                                    {beställning.mobil}
                                </td>
                                <td>
                                    {beställning.land}
                                   <br/>
                                    {beställning.stad}
                                   <br/>
                                    {order.postnummer}
                                   <br/>
                                    {beställning.adress}
                                </td>
                                <td>
                                    {order.leveransmetod}
                                   <br/>
                                    {order.shippingMethod === "posti" && <span title="{JSON.stringify(order.pickupPointData)}">{order.pickupPoint}}</span>
                                    {order.shippingMethod === "direct" && <span>{order.nursingHome}}</span>
                                </td>
                                <td>{order.isPaid.toString()}</td>
                                <td>
                                    {order.betalningsmetod}
                                    {order.subscriptionId && (
                                        <div>
                                            <a href="{`https://dashboard.stripe.com/${process.env.REACT_APP_API_ENDPOINT?.includes(&quot;.dev.&quot;)" ?="" "test="" "="" :="" ""}subscriptions="" ${order.subscriptionId}`}="">Visa Stripe-prenumeration</a>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {order.totalPrice}
                                    <br>
                                    {order.services.map((item: any, index: any) => (
                                        <row key="{index}">
                                            <p className="">{item.name}</p>

                                            <p className="selected-product-price">{item.price} €</p>
                                        </row>
                                    ))}
                                    {order.products?.map((item: any, key: number) => (
                                        <row key="{key}">
                                            <p className="">{item.name}</p>

                                            <p className="selected-product-price">{item.price} €</p>
                                        </row>
                                    ))}
                                </td>
                                <td>{order.shipmentId}</td>
                                <td>{order.coupon}</td>
                                <td>{order.createdAt}</td>
                                <td>{order.updatedAt}</td>
                                {!user.readOnly && user.isAdmin && (
                                    <td>
                                        <button variant="danger" className="btn-sm" onClick="{()" ==""> deleteHandler(order.id)}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default Orders;
