import { Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./osta.css";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/types";
import { Link } from "react-router-dom";

const Osta = () => {
    const title = "Osta | DigihappyOsta senioripuhelin, senioritabletti tai turvaranneke palveluna ";
    const description = "Osta senioripuhelin, senioritabletti tai turvaranneke. Kaikki valmiina palveluna ja it-tuki. Suomi, ruotsi, englanti.";
    const currentDate = Date.now();
    const products = [
        {
            title: "SENIORITABLETTI",
            image: "/assets/icons/tablet.webp",
            description: "Saat kaiken valmiina palveluna. Maksat kuukausimaksun, johon sisältyy kaikki. Tabletti, liittymä, jalusta, Digihappy videopuhelut, optimoitu kotinäkymä, asetukset, it-tuki ja etähallinta. Tabletti on Digihappyn.",
            link: "/osta/senioritabletti",
        },
        {
            title: "SENIORIPUHELIN",
            image: "/assets/icons/mobile.png",
            description:
                "Saat kaiken valmiina palveluna. Maksat erikseen puhelimen ja kuukausimaksun. Omistat puhelimen. Kuukausimaksuun sisältyy Digihappy videopuhelut, it-tuki, etähallinta, muokattu kotinäkymä ja asetukset, Liittymä valintasi mukaan.",
            link: "/osta/senioripuhelin",
        },
        {
            title: "TURVARANNEKE",
            image: "/assets/icons/watch.png",
            description: "Saat kaiken valmiina palveluna. Maksat erikseen rannekkeen ja kuukausimaksun. Omistat rannekkeen. Kuukausimaksuun sisältyy hälytyspuhelut läheisille,liittymä paikannukseen ja puheluihin, it-tuki ja etähallinta.",
            link: "/osta/turvaranneke",
        },
    ];

    const resources = useSelector((state: AppState) => state.alerts);
    const { list } = resources;

    return (
        <>
            <helmet>
                <title>{titel}</title>
                <meta name="description" content="{beskrivning}">
            </helmet>
            <container 56="" fluid="" style="{{" height:="" `calc(100vh="" -="" ${="" +="" *="" list?.filter((el)=""> {
                                // @ts-ignore
                                return (!el.start || new Date(el.start) <= currentDate) && (!el.end || new Date(el.end) >= currentDate);
                            })?.length -
                        1
                    }px)`,
                    width: "100vw",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url('/assets/images/banners/helppo-tabletti-seniorille.jpg')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    minHeight: 640,
                }}
                aria-label="Nainen käyttää tablettia, jonka näytöllä näkyy yhteystietoluettelo."
                className="img-fluid contact-pic"
            >
                <div className="global-banner-text-container">
                    <h1 className="global-banner-title">Köp</h1>
                    <p className="global-banner-text">Köp en Digihappy seniorsurfplatta, seniortelefon eller trygghetslarm</p>
                </div>
            </container>
            <container className="global-content global-page-description" fluid="">
                <p className="global-text global-text-normal">Välj den tjänst du vill ha. Beställningen kan göras av en närstående eller en senior.</p>
            </container>
            <container className="global-content" fluid="">
                <div className="osta-grid-container">
                    {produkter.map((produkt, nyckel) => (
                        <div className="osta-grid-column" key="{key}">
                            <productcard product="{product}"></productcard>
                        </div>
                    ))}
                </div>
            </container>
        </>
    );
};

const ProductCard = ({ product }: { product: { title: string; image: string; description: string; link: string } }) => {
    return (
        <link to="{product.link}">
            <card className="osta-card-2024">
                <card.title className="image-text text-center osta-card-title">{produkt.titel}</card.title>
                <div className="osta-image-container">
                    <img src="{product.image}" alt="T" className="osta-card-image">
                </div>
                <card.text className="image-text text-center osta-card-text osta-card-description">{produkt.beskrivning}</card.text>
            </card>
        
    );
};

export default Osta;
