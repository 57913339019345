importera {Container} från 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <>
            <container fluid="">
                <section className="privacy-policy-text">
                    <div className="privacy-policy-dates-container ">
                        {/*<div className="privacy-policy-dates-left">*/}
                        {/*    Laatimispäivämäärä: 14.6.2021*/}
                        {/*</div>*/}
                        {/*<div className="privacy-policy-dates-right">*/}
                        {/*    Viimeisin muutos: 14.6.2021*/}
                        {/*</div>*/}
                    </div>
                    <div className="privacy-policy-header">
                        Integritetspolicy
                    </div>
                    <div className="text-for-companies-first">
                        <p>
                            Detta är ett integritets- och dataskyddsmeddelande i enlighet med EU:s allmänna dataskyddsförordning (GDPR).
                            Datum för utarbetande är 14.6.2021. <i>Den senaste ändringen gjordes 12.7.2024.</i>
                        </p>
                        <p>
                            Registerhållare
                        </p>
                        <p>
                            Företaget kontrollerar den information som du lämnar och är ansvarigt för personuppgifterna i enlighet med dataskyddslagen.
                        </p>
                        <p>
                            Information om företaget
                        </p>
                        Digihappy Oy
                        <br>
                        Otakaari 5 A
                        <br>
                        02150 Esbo
                        <br>
                        Tel. 010 517 2070
                    </div>

                    <div className="privacy-policy-header2">
                        Namn på registret
                       <br/>
                        Digihappy Oy:s kundregister
                       <br/>
                        <br/>
                        Rättslig grund och syfte med användningen av personuppgifter
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Enligt EU:s allmänna dataskyddsförordning är en individs informerade samtycke, t.ex. ett avtal, ett
                            en rättslig grund för behandling av personuppgifter. Tjänsten registrerar också de faktiska
                            av kundens nära anhöriga, och kunden måste ha de berörda personernas samtycke för att få lämna ut denna information.
                        </p>
                        <p>
                            Syftet med behandlingen av personuppgifter är att kontakt till kunderna, tillhandahållandet av tjänsten och upprätthållandet av kundrelationen. Uppgifterna kommer inte att användas för automatiserat beslutsfattande eller profilering.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Registrets datainnehåll
                    </div>
                    <div className="text-for-companies-first">
                        <p>
                            Registret får behandla följande kategorier av uppgifter som är relevanta för registrets syfte: 
                            </p><ul>
                                <li>Namn</li>
                                <li>Kontaktinformation (t.ex. adresser, telefonnummer, e-postadresser)</li>
                                <li>Språk</li>
                            </ul>
                        <p></p>
                        <p>
                            Information om kunden och andra relevanta kontakter, t.ex:
                            </p><ol>
                                <li>
                                    Start- och sluttid för den aktuella kontakten
                                </li>
                                <li>
                                    Identifierande information relaterad till användningen av Tjänsterna (t.ex. kundnummer,
                                    användarnamn och lösenord) och identifieringsuppgifter för elektronisk kommunikation (t.ex.
                                    IP-adress) och tjänstealternativ
                                </li>
                                <li>
                                    Information om inköp som gjorts i egenskap av känd kund och de olika stegen i inköpsprocessen
                                </li>
                                <li>
                                    Information om fakturering och indrivning
                                </li>
                                <li>
                                    Kontakt och kommunikation med kunder och andra relevanta kontakter i olika kanaler och
                                    kanaler och medier (t.ex. feedback och inspelningar av kundtjänstsamtal)
                                </li>
                                <li>
                                    Tillstånd och förbud för direktmarknadsföring och information med inriktning på marknadsföring
                                </li>
                                <li>
                                    Uppgifter som framställts av eller som rör den registrerade.
                                </li>
                                <li>
                                    Information om anmälningar som offentliggjorts av den registrerade
                                </li>
                                <li>
                                    Uppgifter om händelse- och användaranalyser
                                </li>
                                <li>
                                    Ändringar av den information som anges ovan
                                </li>
                            </ol>
                        <p></p>
                    </div>

                    <div className="privacy-policy-header">
                        Regelbundna informationskällor
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Personuppgifter samlas in från den registrerade själv och från den registeransvariges system närden registrerade registrerar sig i och använder tjänsterna. Personuppgifter kan samlas in och uppdateras av den registeransvarige och hos företag som hör till samma koncern, befolkningsdatasystemet,
                            kreditupplysningsregister, ASML Finlands Kundmarknadsföringsförening ry och andra liknande offentliga eller privata register som tillhandahåller liknande register och datakällor samt från den registeransvariges samarbetspartners.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Utlämnande och överföring av uppgifter
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Uppgifterna kommer inte att överföras utanför Europeiska unionen eller Europeiska ekonomiska samarbetsområdet om de inte är nödvändigt för det tekniska genomförandet av tjänsten. I sådana fall ska den registeransvarige garantera en adekvat nivå av dataskydd enligt lagens krav.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Skydd av registret
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Digihappy Oy:s kunddatabas finns i Europeiska ekonomiska samarbetsområdet Amazon Web Services (AWS)
                            molntjänstleverantör och är därför mycket tekniskt säker för att förhindra obehörig extern åtkomst. Molntjänstleverantörerna skyddar sina kunder
                            endast från externa tekniska hot och Digihappy Oy är enligt användarvillkoren personligen ansvarig för att
                            för sin egen miljö. I den mån Digihappy är ansvarig för sin egen tekniska infrastruktur, underhåller och skyddar vi vår egen infrastruktur.
                        </p>
                        <p>
                            Registret används endast av personer som är utbildade för den uppgift för vars utförande registret är
                            användningen av uppgifterna är nödvändig. Alla åtkomsträttigheter till registret är arbetsrelaterade och
                            varje användare har endast de rättigheter som är nödvändiga för hans eller hennes uppgift. Registret skyddas av
                            tekniska kontroller, som är under Digihappy Oy:s kontroll och är föremål för kontinuerlig övervakning.
                            föremål för kontinuerlig övervakning.
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Lagringsperiod för uppgifter
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Den registrerades personuppgifter sparas endast under den tid som krävs för att uppfylla de användningsändamål som beskrivs i detta meddelande. Dessutom kan vissa uppgifter sparas längre, i den mån det är nödvändigt för att uppfylla rättsliga skyldigheter, t.ex.
                            bokförings- och konsumentansvar, och för att kunna fullgöra dessa skyldigheter på ett korrekt sätt. 
                        </p>
                    </div>

                    <div className="privacy-policy-header">
                        Rätt till inspektion, förbud och rättelse
                    </div>

                    <div className="text-for-companies-first">
                        <p>
                            Den registrerade har rätt att kontrollera vilka uppgifter om honom eller henne som har förts in i registret.
                            Begäran om kontroll ska skickas skriftligen, undertecknad och adresserad till
                            den person som är ansvarig för registret. Begäran om kontroll kan också göras personligen i den registeransvariges lokaler på ovanstående adress. En begäran om granskning kan göras
                            kostnadsfritt en gång om året. Begäran om kontroll kommer att besvaras senast en månad efter det att begäran har lämnats in.
                        </p>
                        <p>
                            De registrerade har rätt att motsätta sig att deras uppgifter används för direktannonsering, distansförsäljning eller annan direktmarknadsföring, samt opinionsundersökningar och marknadsundersökningar. Den registrerade har också rätt att få ändring av felaktiga uppgifter eller radering av alla sina uppgifter
                            genom att informera den personuppgiftsansvariges kundtjänst på <a href="mailto:digihappy@mediti.fi">digihappy@mediti.fi</a>.
                        </p>
                    </div>
                </section>

            </container>
        </>
    )
}

export default PrivacyPolicy
