import React, { Component } from "react";
import api from "../utils/api-helper/api";
import { Link } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";

import FormContainer from "../components/FormContainer";
import Message from "../components/Message";

class UpdatePassword extends Component {
    state = {
        userId: undefined,
        token: undefined,
        password: "",
        confirmPassword: "",
        submitted: false,
        message: "",
        showPass: false,
        showPass2: false,
    };

    componentDidMount() {
        //@ts-ignore
        const { userId, token } = this.props?.match.params;
        this.setState({
            userId: userId,
            token: token,
        });
    }

    handleChange = (key: any) => (e: React.ChangeEvent<htmlinputelement>) => {
        const obj = this;
        this.setState({ [key]: e.target.value }, function () {
            let msg = "";
            if (obj.state.password.length > 0 && obj.state.password.length < 11) {
                msg += "Salasana on liian lyhyt";
            } else if (obj.state.password.length > 0 && obj.state.confirmPassword.length > 0 && obj.state.password !== obj.state.confirmPassword) {
                msg += "Salasanat eivät täsmää\n";
            }
            obj.setState({
                message: msg,
            });
        });
    };

    updatePassword = (e: React.FormEvent) => {
        e.preventDefault();
        //@ts-ignore
        const { password, userId, token } = this.state;
        api.post(`/email/new-password/${userId}/${token}`, {
            password,
        })
            .then((res) => console.log("RESPONSE FROM SERVER TO CLIENT:", res))
            .catch((err) => console.log("SERVER ERROR TO CLIENT:", err));
        this.setState({ submitted: !this.state.submitted });
    };

    showPassHandler = () => {
        this.setState({
            showPass: !this.state.showPass,
        });
    };
    showPassHandler2 = () => {
        this.setState({
            showPass2: !this.state.showPass2,
        });
    };

    render() {
        const { submitted, showPass, showPass2 } = this.state;
        return (
            <formcontainer>
                <h3 style="{{" paddingBottom:="" "1.25rem",="" paddingTop:="" "2rem"="" }}="">Ändra lösenord</h3>
                {this.state.message && (
                    <message className="message" variant="danger">
                        {this.state.message}
                    </message>
                )}
                {inlämnad ? (
                    <div className="reset-password-form">
                        <p>Ditt lösenord har ändrats.</p>
                        <link to="/kirjaudu" className="tallenna">
                            Logga in
                        
                    </div>
                ) : (
                    <div className="reset-password-form">
                        <form onSubmit="{this.updatePassword}" style="{{" paddingBottom:="" "1.5rem"="" }}="">
                            <form.label htmlFor="password" className="form-label">
                                Nytt lösenord
                            </form.label>
                            <form.group>
                                <inputgroup>
                                    <form.control required="" onChange="{this.handleChange(&quot;password&quot;)}" value="{this.state.password}" placeholder="Uusi salasana" type="{showPass" ?="" "text"="" :="" "password"}=""></form.control>
                                    <button variant="outline-secondary shadow-none show-password-btn" onClick="{this.showPassHandler}">
                                        <i className="{showPass" ?="" "fas="" fa-eye-slash"="" :="" fa-eye"}=""></i>
                                    </button>
                                </inputgroup>
                                <div className="show-password">Klicka på ögonikonen för att se ditt lösenord</div>
                            </form.group>
                            <form.label htmlFor="password" className="form-label">
                                Upprepa lösenordet
                            </form.label>
                            <form.group>
                                <inputgroup>
                                    <form.control required="" onChange="{this.handleChange(&quot;confirmPassword&quot;)}" value="{this.state.confirmPassword}" placeholder="Vahvista salasana" type="{showPass2" ?="" "text"="" :="" "password"}=""></form.control>
                                    <button variant="outline-secondary shadow-none show-password-btn" onClick="{this.showPassHandler2}">
                                        <i className="{showPass2" ?="" "fas="" fa-eye-slash"="" :="" fa-eye"}=""></i>
                                    </button>
                                </inputgroup>
                                <div className="show-password">Klicka på ögonikonen för att se ditt lösenord</div>
                            </form.group>
                            <button 11="" type="submit" className="btn-primary password-reset-btn tallenna" disabled="{this.state.password.length" <="" ||="" this.state.password="" !="=" this.state.confirmPassword}="">
                                Spara
                            </button>
                        </form>
                    </div>
                )}
            </formcontainer>
        );
    }
}

export default UpdatePassword;
</htmlinputelement>