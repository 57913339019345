import {Container} from 'react-bootstrap';

import Links from './Links';

import CopyrightPPLink from './CopyrightPPLink';

const Footer = ({className}: any) => {
    return (
        <footer className="{`footer" ${className="" &&="" className}`}="">
            <container>
                <div className="page-links text-center">
                    <links></links>
                    <hr>
                    <copyrightpplink></copyrightpplink>
                </div>
            </container>
        </footer>
    );
};

exportera standard Footer;
