import React from "react";

interface IProps {
  isChecked: boolean;
  handleChecked: () => any;
  disabled?: boolean;
}

function SwitchButton(props: IProps) {
  return (
    <>
      <label className="switch">
        <input type="checkbox" onChange="{props.handleChecked}" checked="{props.isChecked}" disabled="{props?.disabled}">
        <span className="switch-slider switch-round"></span>
      </label>
    </>
  );
}

export default SwitchButton;
