import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./PrivacyPolicy";
import { useEffect } from "react";

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <helmet>
                <title>Digihappy användarvillkor och sekretesspolicy | Digihappy</title>
                <meta name="description" content="Digihappys användarvillkor och integritetspolicy. God service och dataskydd är genuint viktigt för oss.">
            </helmet>
            <container fluid="" style="{{" textAlign:="" "justify",="" }}="">
                <section className="privacy-policy-text">
                    <h1 className="global-banner-title">Digihappy</h1>
                    <div className="privacy-policy-dates-container ">
                        <div className="privacy-policy-header privacy-policy-dates-left">Digihappy användarvillkor och sekretesspolicy</div>
                    </div>
                    <div className="privacy-policy-header text-for-companies-first">
                        För att underlätta ditt läsande har vi bedömt att följande är de viktigaste villkoren för dig:
                        <h2 className="global-privacy-header">SENIORSURFPLATTA SOM TJÄNST</h2>
                        <ol>
                            <li>Du betalar en månadsavgift för full service. Avgiften debiteras i 3-månadersperioder.</li>
                            <li>Tjänsten kan alltid sägas upp minst 7 dagar före den nya (3-månaders) serviceperioden börjar.</li>
                            <li>
                                Som regel ingår allt i tjänsten: en färdig surfplatta, stativ, lång kabel, skyddsfilm, anslutning, data. Videosamtal med Digihappy-appen. Appar och inställningar klara. Personlig
                                startrådgivning, IT-support, uppdateringar och fjärrhantering.
                            </li>
                            <li>Utrustning och tillbehör tillhör Digihappy. Digihappy ansvarar för att du har en fungerande enhet.</li>
                            <li>
                                Löpande IT-support och fjärrhantering ingår. Antalet timmar per månad med kontinuerlig kostnadsfri personlig IT-support kan dock vara begränsat och kan variera mellan olika versioner av tjänsten. Denna information
                                ingår i tjänstebeskrivningarna på webbplatsen. Om det skulle uppstå ett fel på en enhet eller tjänst som är relaterad till oss, kommer du alltid att vara kostnadsfri.
                            </li>
                        </ol>
                        <h2 className="global-privacy-header">SENIORTELEFON SOM TJÄNST</h2>
                        <ol>
                            <li>
                                Du betalar en telefon- och en personlig installations- och inledande konsultationsavgift i början. Du betalar en månatlig serviceavgift. Du kan också få ett abonnemang för en månadsavgift från Digihappy, eller så kan du använda ditt eget abonnemang.
                                Digihappys abonnemangsinformation finns i tjänstebeskrivningarna.
                            </li>
                            <li>Du kan alltid säga upp din tjänst och ditt abonnemang senast 7 dygn innan den nya perioden (1 månad) börjar löpa.</li>
                            <li>
                                I tjänsten ingår en färdig telefon med alla appar och inställningar. Videosamtal med Digihappy-appen. Löpande it-support, uppdateringar och fjärrhantering. Personlig inledande rådgivning vid starten,
                                för att hjälpa dig att ställa in din telefon.
                            </li>
                            <li>
                                Telefonen och tillbehören är dina. Om du slutar använda Digihappy-tjänsten kommer vi att ta bort fjärrhantering, anpassad startskärm, funktioner, inställningar och ditt Digihappy-videosamtalskonto. Din telefon kommer att fungera som vanligt
                                telefon.
                            </li>
                            <li>
                                Löpande IT-support och fjärrhantering ingår. Antalet timmar per månad med kontinuerlig kostnadsfri personlig IT-support kan dock vara begränsat. Denna information finns i tjänstebeskrivningarna på hemsidan. I händelse av
                                ett fel på en enhet eller tjänst som är relaterad till oss, kommer du alltid att vara kostnadsfri för att få det löst.
                            </li>
                        </ol>
                        <h2 className="global-privacy-header">SÄKERHETSARMBAND SOM TJÄNST</h2>
                        <ol>
                            <li>Du betalar ett säkerhetsarmband och en personlig installations- och inledande konsultationsavgift i början. Du betalar en månatlig service- och abonnemangsavgift.</li>
                            <li>Du kan alltid säga upp din tjänst och ditt abonnemang senast 7 dygn innan den nya perioden (1 månad) börjar löpa.</li>
                            <li>
                                I tjänsten ingår en uppkoppling (som behövs för att säkerhetsarmbandet ska fungera, dvs positionering och röstuppkoppling), som inte är beroende av en mobiltelefon, samt löpande IT-support och uppdateringar. Inledningsvis en personlig
                                initialt personliga råd som hjälper dig att installera armbandet.
                            </li>
                            <li>Säkerhetsarmbandet och tillbehören är dina. Digihappy kan köpa tillbaka det från dig till ett pris som ska överenskommas om det är i gott skick och har använts i mindre än 12 månader.</li>
                            <li>
                                Löpande IT-support ingår i tjänsten. Antalet timmar per månad med kontinuerlig kostnadsfri personlig IT-support kan dock vara begränsat. Denna information finns i tjänstebeskrivningarna på hemsidan. I händelse av att en enhet
                                eller tjänst inte fungerar som den ska är det alltid kostnadsfritt för dig att få det åtgärdat.
                            </li>
                        </ol>
                    </div>

                    <div className="privacy-policy-header text-for-companies-first">
                        <h2 className="global-privacy-header">DATASKYDD</h2>
                        Viktig information om dataskydd:
                        <ol>
                            <li>Syftet med informationen som kunden lämnar till oss (t.ex. för- och efternamn och kontaktuppgifter) är att kontakta kunderna, tillhandahålla den beställda tjänsten och upprätthålla kundrelationen.</li>
                            <li>Uppgifterna kommer inte att överföras till andra parter om det inte är nödvändigt för det tekniska genomförandet av tjänsten. I sådana fall kommer vi att säkerställa en adekvat nivå av dataskydd i enlighet med lagens krav.</li>
                            <li>Databaserna med vår kundinformation är strängt skyddade. Vi sparar kunduppgifter endast under den tid som krävs.</li>
                            <li>Kunden har rätt att kontrollera vilka uppgifter om honom/henne som har sparats i registret.</li>
                        </ol>
                    </div>

                    <div className="privacy-policy-header">För tjänstens kunder</div>
                    <div className="text-for-companies-first">
                        <p>
                            Dessa Användar- och tjänstevillkor är avsedda för dig som köper våra tjänster. Kunden är ofta en närstående till den äldre, men kunden kan också vara den äldre själv. Du hittar villkoren på den här webbplatsen
                            och för mer information är du välkommen att kontakta vår kundtjänst, vars kontaktuppgifter finns på vår webbplats.
                        </p>
                        <p>Om du har en prov- eller testperiod är tjänsten kostnadsfri under denna period och binder dig inte till ytterligare beställningar. Prov- eller testperioden kan innebära en leveransavgift.</p>
                    </div>

                    <div className="privacy-policy-header">Skapa ett tjänsteavtal, ett kundkonto och ett familjekonto.</div>

                    <div className="text-for-companies-first">
                        <p>
                            Ett serviceavtal skapas när du registrerar dig för Digihappy-tjänsten på vår webbplats och anger att du accepterar villkoren eller prenumererar på vår tjänst, t.ex. via e-post eller telefon. Vi skapar ett kundkonto åt dig
                            För surfplatte- och telefontjänster hanterar du också ett seniorkonto och kan lägga till fem andra familjemedlemmar eller vänner vars kontaktuppgifter laddas upp till din enhet. Varje närstående har sitt eget konto
                            på vår tjänst. Detta paket kallas familjekonto och ingår i dessa tjänster.
                        </p>
                        <p>Genom att använda Digihappys tjänster godkänner du att följa din del av dessa villkor. Villkoren gäller under hela serviceavtalets giltighetstid.</p>
                    </div>

                    <div className="privacy-policy-header">Tjänst</div>

                    <div className="text-for-companies-first">
                        <p>Vår tjänst är utformad för att göra det enklare för den äldre du bryr dig om att hålla kontakten med dig och andra nära och kära, och för att underlätta deras användning av digitala tjänster.</p>
                        <p>
                            Det finns olika versioner av tjänsterna för att säkerställa att varje äldre person och familj får bästa möjliga tjänst som uppfyller deras behov. Information om de olika versionerna finns på vår webbplats. Obegränsad onlineåtkomst ingår
                            några av tjänsterna. Obegränsad data på SIM-kortet gäller naturligtvis bara för den äldre och bara för den äldres enhet.
                        </p>
                        <p>
                            Vi kan också erbjuda dig andra tilläggstjänster eller -produkter avgiftsfritt eller mot en avgift. Våra kunder kan till exempel prenumerera på Celias ljudbokstjänst, varvid de också förbinder sig till Celias
                            villkor för tjänsten.
                        </p>
                        <p>
                            Digihappy-tjänsten inkluderar enkla videosamtal med Digihappy-appen. Autosvar för videosamtal är tillgängligt på surfplattor och telefoner. Denna funktion är valfri. Tjänsten är tillgänglig på
                            I abonnemangsskedet av tjänsten väljer kunden vilka funktioner, såsom autosvar och språk, och applikationer som önskas. Standardalternativet är det mest populära valet, dvs. automatiskt svar på en surfplatta.
                            Automatisk svar på surfplattan och svar med ett klick på telefonen (icke-automatisk), finska språket och de populäraste apparna i åldersgruppen. Den som abonnerar på tjänsten, dvs. kunden, som oftast är en nära anhörig till den äldre eller den äldre själv, svarar
                            att han/hon har rätt att välja ett automatiserat svar, om tillämpligt. Om kunden vill ändra sitt val kan han/hon göra det genom att informera Digihappy it-support, som kan ändra valet till
                            med hjälp av fjärrstyrning. Om kunden är en vårdgivare ansvarar vårdgivaren för att säkerställa att denne har kundens eller dennes behöriga företrädares tillstånd att göra ett automatiserat svarsval. Digihappy
                            ger råd till tjänsteleverantörer.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Leverans av tjänsten</div>

                    <div className="text-for-companies-first">
                        <p>
                            Vi levererar den fysiska utrustningen till kundens hem eller direkt till vård- eller servicehemmet i samband med samleveranser, enligt överenskommelse. Utrustningen installeras så att den är så klar för användning som möjligt, och
                            den inledande konsultationen omfattar slutförandet av eventuella nödvändiga personliga inställningar, beroende på tjänsten. Vi installerar också de applikationer som behövs för fjärrhanteringstjänsten, så att vi kan ge IT-support och
                            utföra uppdateringar.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Avgift för tjänsten och avtalets löptid</div>

                    <div className="text-for-companies-first">
                        <p>Serviceavgifterna och avtalets löptid anges i början av denna text för varje tjänst.</p>
                    </div>

                    <div className="privacy-policy-header">Betalning, fakturering och priser</div>

                    <div className="text-for-companies-first">
                        <p>Priserna för tjänsten, tilläggstjänster och tilläggsprodukter finns tillgängliga på vår webbplats. Priserna kan komma att ändras. Vi kommer dock att meddela dig om eventuella prisändringar innan vi tillämpar dem.</p>
                        <p>
                            Om din betalning är försenad med mer än 14 dagar har vi rätt att avbryta tjänsten och ta ut dröjsmålsränta enligt lagstadgad räntesats samt en rimlig påminnelseavgift.
                            av betalningspåminnelsen.
                        </p>
                        <p>
                            Vid beställning med kortbetalning är vår betalningspartner Stripe. Stripe är en säker och pålitlig betalningsmetod.{""}
                           <a className="linkki" href="https://www.forbes.com/advisor/business/software/stripe-review/" target="_blank" rel="noreferrer">
                                Forbes finanstidning </a>
                            {" "}
                            Stripe valdes ut som en av de bästa betalningspartnerna för onlinebetalningar. Genom att beställa med kort godkänner du också Stripes villkor, som du kan läsa mer om här{""}
                           <a className="linkki" href="https://stripe.com/en-fi/ssa" target="_blank" rel="noreferrer">
                                här
                           </a>
                            .
                        </p>
                    </div>

                    <div className="privacy-policy-header">Tillgänglighet och stöd för tjänster</div>

                    <div className="text-for-companies-first">
                        <p>Det finns flera stödkanaler för dig och dina nära och kära, till exempel e-post, telefon och WhatsApp.</p>
                        <p>
                            Vi strävar alltid efter att besvara dina supportförfrågningar så snart som möjligt och senast inom 24 timmar på vardagar. Om det uppstår en försening i vår service på grund av plötslig händelse eller force majeure, kommer vi att försöka informera dig om detta.
                            genom ett svarsmeddelande på supportbegäran.
                        </p>
                        <p>
                            Om det uppstår ett problem med tjänsten som beror på ett tidigare okänt fel eller en funktion hos en tredjepartsleverantör som är oberoende av oss, eller en störning, t.ex. ett fel i operatörens nätverkstjänster,
                            kommer vi att återställa tjänsten så snart som möjligt efter det att störningen hos tredjepartsleverantören har upphört.
                        </p>
                        <p>Naturligtvis har du konsumentskydd enligt finsk lag. Vi tillhandahåller tjänsten, tilläggstjänsterna och tilläggsprodukterna som sådana, utan några uttryckliga eller underförstådda garantier.</p>
                    </div>

                    <div className="privacy-policy-header">Uppsägning av beställningen</div>

                    <div className="text-for-companies-first">
                        <p>
                            Du kan säga upp ditt abonnemang upp till 7 kalenderdagar före den nya faktureringsperiodens början, i vilket fall du inte längre kommer att debiteras för den nya faktureringsperioden. För en tjänst där Digihappy äger utrustningen, under förutsättning att
                            Du har returnerat den utrustning som omfattas av Tjänsten till oss, med alla dess tillbehör. Returen kommer att ske med post och du ansvarar för portot för returen. Vi kommer att hämta surfplattan och stativet för återlämning till
                            återanvända eller, om nödvändigt, återvinna dem på lämpligt sätt. På så sätt sparar vi på miljön och är ett ansvarsfullt företag.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Kundens ansvar för utrustning och tillbehör som tillhör Digihappy</div>

                    <div className="text-for-companies-first">
                        <p>
                            För din del ber vi dig att ta hand om din surfplatta, sitt stativ och SIM-kort på bästa möjliga sätt, inom ramen för dina möjligheter. Du eller en närstående får inte använda Digihappy-tjänsten på ett sätt som strider mot användarvillkoren, god sed eller
                            finsk lag.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Ändringar i tjänsten</div>

                    <div className="text-for-companies-first">
                        <p>
                            Vi förbättrar ständigt vår tjänst, till exempel genom att köra nya uppdateringar eller installera eller ersätta nya beprövade och testade appar på din surfplatta. Vi kan således lägga till, ändra eller ta bort
                            funktioner, tjänster eller servicetider. Vi kommer att meddela dig om väsentliga förändringar av tjänsten. Vi kan göra mindre ändringar utan att dessa föregås av meddelande.
                        </p>
                        <p>Om vi slutar tillhandahålla tjänsten, vilket inte är vårt mål, kommer vi att ge dig minst 1 månads varsel och återbetala eventuell oanvänd del av serviceavgiften.</p>
                    </div>

                    <div className="privacy-policy-header">Ändringar av avtalsvillkoren</div>

                    <div className="text-for-companies-first">
                        <p>
                            Vi kommer att uppdatera villkoren allteftersom tjänsten utvecklas. Vi publicerar den senaste versionen på vår webbplats. Du kommer att underrättas om alla ändringar som vi anser vara väsentliga. Genom att fortsätta använda tjänsten med de senaste
                            villkoren, tolkar vi det som att du har accepterat ändringarna.
                        </p>
                        <p>Vi meddelar dig om alla väsentliga ändringar av villkoren en månad innan de träder i kraft. Inom två veckor efter att du informerats om ändringen har du rätt att säga upp avtalet med omedelbar verkan.</p>
                    </div>

                    <div className="privacy-policy-header">Immateriella rättigheter</div>

                    <div className="text-for-companies-first">
                        <p>
                            Alla immateriella rättigheter till Tjänsten, dess innehåll, egenskaper och funktionalitet samt eventuella senare ändringar av dessa ägs av oss. Under avtalstiden ges du och dina närstående rätt att använda Tjänsten i enlighet med
                            använda Tjänsten i enlighet med Avtalet och villkoren i Avtalet. All kopiering, reproduktion, vidaresändning, distribution eller användning av Tjänsten eller någon del eller delar av Tjänsten annat än i enlighet med dessa villkor är förbjuden.
                            i enlighet med dessa villkor är förbjuden.
                        </p>
                    </div>

                    <div className="privacy-policy-header">Tillämplig lag och tvistlösning</div>

                    <div className="text-for-companies-first">
                        <p>
                            Vi kommer alltid att försöka hitta en gemensam lösning tillsammans med dig genom ömsesidig överenskommelse. Om vi mot förmodan skulle hamna i en situation som vi inte kan komma överens om tillsammans, kommer finsk lag att tillämpas på avtalsvillkoren. Du har alltid rätt att
                            du kan alltid vända dig till Konsumentrådgivningen och Konsumenttvistenämnden och i extrema fall avgörs tvister av allmän domstol.
                        </p>
                    </div>
                </section>
            </container>
            <privacypolicy></privacypolicy>
        </>
    );
};

export default TermsOfUse;
