import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { deleteCouponRequest, getCouponsRequest } from "../redux/actions";
import { AppState } from "../redux/types";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const CouponsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getCouponsRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const resources = useSelector((state: AppState) => state.coupons);
  const { list, loading, error } = resources;

  const deleteHandler = (id: any) => {
    if (window.confirm("Haluatko varmasti poistaa kupongin?")) {
      dispatch(deleteCouponRequest(id));
    }
  };

  const addNewCoupon = () => {
    history.push("/admin/coupon/new");
  };

  return (
    <>
      <h1 className="my-3 ml-3" style="{{" color:="" "#4e5180",="" fontFamily:="" "Poppins,="" sanf-serif"="" }}="">
        Kuponger
      </h1>
      {user.isAdmin && <button onClick="{addNewCoupon}">Lägg till en ny kupong</button>}
      {laddning ? (
        <loader></loader>
      ) : error ? (
        <message variant="danger">{fel}</message>
      ) : (
        <table striped="" bordered="" hover="" responsive="" className="users-table">
          <thead className="user-title">
            <tr className="user">
              <th className="text-center">ID</th>
              <th className="text-center">CODE</th>
              <th className="text-center">Fri frakt</th>
              <th className="text-center">PRÖVODAGAR</th>
              <th className="text-center">
                {user.readOnly || !user.isAdmin ? "Näkymä" : "Päivitä /Poista"}
              </th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list?.map((coupon: any) => (
                <tr key="{coupon.id}">
                  <td>{coupon.id}</td>
                  <td>{coupon.code}</td>
                  <td>
                    {coupon?.data?.free_shipping ? (
                      <i className="fas fa-check" style="{{" color:="" "#4e5180"="" }}=""></i>
                    ) : (
                      <i className="fas fa-times" style="{{" color:="" "red"="" }}=""></i>
                    )}
                  </td>
                  <td>{coupon.data?.period}</td>
                  <td>
                    <linkcontainer to="{`/admin/coupon/${coupon.id}`}">
                      <button className="btn-sm">
                        <i className="{" user.readOnly="" ||="" !user.isAdmin="" ?="" "fas="" fa-eye"="" :="" fa-edit"="" }="" style="{{" color:="" "#fff"="" }}=""></i>
                      </button>
                    </linkcontainer>
                    {!user.readOnly && user.isAdmin && (
                      <button variant="danger" className="btn-sm" onClick="{()" ==""> deleteHandler(coupon.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export standard CouponsList;
