import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  createSeniorHomeRequest,
  getSeniorHomesRequest,
} from "../redux/actions";
import { AppState } from "../redux/types";
import { LinkContainer } from "react-router-bootstrap";

const SeniorHomeForm = () => {
  const { id } = useParams<{ id: string }>();
  const resources = useSelector((state: AppState) => state.seniorHomes);
  const { list } = resources;
  let senior_home = undefined;
  try {
    senior_home = list?.find((o) => o.id === parseInt(id));
  } catch (error) {}

  const [formData, setFormData] = useState({
    id: senior_home ? parseInt(senior_home.id) : -1,
    reference: senior_home?.reference || "",
    name: senior_home?.name || "",
    show: typeof senior_home?.show === "boolean" ? senior_home?.show : true,
    createdAt: undefined,
    updatedAt: undefined,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user);
  useEffect(() => {
    if (user && (user.isAdmin || user.role === "it_support")) {
      dispatch(getSeniorHomesRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
    const { value, name } = e.target;
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
        reference: value.replace(/\s/g, "_").toLocaleLowerCase(),
      };
    });
  };

  const handleCheck = (e: React.ChangeEvent<htmlinputelement>) => {
    const { checked, name } = e.target;
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: checked,
      };
    });
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(
      createSeniorHomeRequest({
        id: formData.id === -1 ? undefined : formData.id,
        reference: formData.reference,
        name: formData.name,
        show: formData.show,
        createdAt: formData.createdAt,
        updatedAt: formData.updatedAt,
      })
    );
    dispatch(getSeniorHomesRequest());
    history.push("/admin/senior_homes");
  };

  return (
    <container className="register-form-container">
      <form onSubmit="{submitHandler}" className="service-form">
        <form.group controlId="senior_home-text">
          <form.label>Namn</form.label>
          <form.control placeholder="Name" name="name" value="{formData.name}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="senior_home-reference">
          <form.label>Referens</form.label>
          <form.control placeholder="Reference" name="reference" value="{formData.reference}" onChange="{handleChange}" readOnly="{user.readOnly" ||="" !user.isAdmin}=""></form.control>
        </form.group>
        <form.group controlId="senior_home-show">
          <form.check label="Show" type="checkbox" name="show" checked="{formData.show}" onChange="{handleCheck}" disabled="{user.readOnly" ||="" !user.isAdmin}=""></form.check>
        </form.group>
        <row>
          <col>
            <linkcontainer to="{`/admin/senior_homes`}">
              <button className="tallenna paymentmethod-back-button">
                Tillbaka
              </button>
            </linkcontainer>
          
          {!user.readOnly && user.isAdmin && (
            <col style="{{" textAlign:="" "right",="" }}="">
              <button className="create-service-button tallenna" type="submit" disabled="{!formData.name?.length" ||="" !formData.reference?.length}="">
                Spara
              </button>
            
          )}
        </row>
      </form>
    </container>
  );
};

export standard SeniorHomeForm;
</htmlinputelement></htmlinputelement>