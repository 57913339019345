import React, { createContext, useContext, useMemo } from 'react';
import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SCOKET_ENDPOINT;

const SocketContext = createContext(null);

export const useSocket = () => {
  const socket = useContext(SocketContext);

  return socket;
};

export const SocketProvider = (props: any) => {
  const socket: any = useMemo(() => io(SOCKET_URL ?? ''), []);

  return (
    <socketcontext.provider value="{socket}">
      {props.children}
    </socketcontext.provider>
  );
};
