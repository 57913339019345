import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  getItSupportListRequest,
  removeItSupportProfileRequest,
} from "../redux/actions";
import { AppState } from "../redux/types";

const ITSupport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const itSupport: any = useSelector((state: AppState) => state.itSupport);
  const { loading, error, list } = itSupport;

  useEffect(() => {
    if (user && user.isAdmin) {
      dispatch(getItSupportListRequest());
    } else {
      history.push("/kirjaudu");
    }
    return () => {};
  }, [dispatch, history, user]);

  const handleDeleteItSupport = (id: number) => {
    dispatch(removeItSupportProfileRequest(id));
  };

  return (
    <>
      <div className="alert-message">
        {Object.keys(itSupport.message).length !== 0 && (
          <message 200="" variant="{itSupport.message?.status" =="=" ?="" "info"="" :="" "danger"}="">
            <div className="message-text">{itSupport.message?.message}</div>
          </message>
        )}
      </div>
      {/* <h1 className="my-3 ml-3 module-title">IT-stöd</h1>
      <linkcontainer to="{`/admin/it_support/add`}">
        <button>Lägg till nytt IT-stöd</button>
      </linkcontainer> */}

      <div className="headind-button-div">
        <h1 className="my-3 ml-3 module-title">IT-stöd</h1> {/* IT-stöd */}
        {/* IT-stöd */}
        <linkcontainer to="{`/admin/it_support/add`}">
          <button className="add-button">MER NYTT IT-STÖD</button>
          {/* LÄGG TILL NYTT IT-STÖD */}
        </linkcontainer>
      </div>

      <div>
        {Lastning ? (
          <loader></loader>
        ) : error ? (
          <div className="error-message">
            <message variant="danger">
              <div className="message-text">{error.message}</div>
            </message>
          </div>
        ) : list?.length > 0 ? (
          <table striped="" bordered="" hover="" responsive="" className="users-table">
            <thead className="user-title">
              <tr className="user">
                <th className="text-center">ID</th>
                <th className="text-center">Förnamn</th>
                <th className="text-center">Efternamn</th>
                <th className="text-center">E-postadress</th>
                <th className="text-center">Telefonnummer</th>
                <th className="text-center">Uppdatera/radera</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item: any) => (
                <tr key="{item.userId}">
                  <td className="text-center">{item.userId}</td>
                  <td className="text-center">{item.firstName}</td>
                  <td className="text-center">{item.lastName}</td>
                  <td className="text-center">{item.email}</td>
                  <td className="text-center">{item.mobileNumber}</td>
                  <td className="text-center">
                    <linkcontainer className="action-items" to="{`/admin/it_support/${item.userId}`}">
                      <button className="btn-sm">
                        <i className="fas fa-edit" style="{{" color:="" "#fff"="" }}=""></i>
                      </button>
                    </linkcontainer>
                    {
                      <button variant="danger" className="btn-sm" onClick="{()" ==""> handleDeleteItSupport(item.userId)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <message variant="light">IT-stödet är inte tillgängligt!!!</message> // No IT Support Available!!
        )}
      </div>
    </>
  );
};

export default ITSupport;
