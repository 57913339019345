import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { updateUserRequest } from "../redux/actions";
import Message from "./Message";
import Loader from "./Loader";
import { AppState } from "../redux/types";

const FeatureChoicesPhone = ({ onSelect, key }: any) => {
    const member = useSelector((state: AppState) => state.user);
    const { error, loading } = member;
    const [serviceLang, setServiceLang] = useState(member?.tabletLanguage || "suomi");
    const [autoAnswer, setAutoAnswer] = useState(member?.autoAnswer || "false");
    const [option, setOption] = useState(member?.serviceOption || "puhelimen3");
    const relativeDoneEmailId = useSelector((state: AppState) => state.categories.category?.attributes.RelativeAccountDone);

    const dispatch = useDispatch();

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        dispatch(
            updateUserRequest({
                ...member,
                password: "",
                tabletLanguage: serviceLang,
                autoAnswer: autoAnswer,
                serviceOption: option,
                relativeDoneEmailId,
                chosenService: "phone",
            })
        );

        setTimeout(function () {
            onSelect("tilaus");
        }, 3000);
    };

    const auto_answer = (
        <form.group className="feature_choices-form">
            <label>Öppna ett videosamtal på den äldres telefon när en familjemedlem ringer:</label>
            <div style="{{" marginLeft:="" 127,="" }}="">
                <form.check className="radio-button" type="radio" label="{`Videopuhelu" avautuu="" automaattisesti="" ilman="" kosketusta="" `}="" id="true" name="autoAnswer" value="{&quot;true&quot;}" checked="{autoAnswer" =="=" "true"}="" onChange="{()"> setAutoAnswer("true")}
                />
                <form.check className="radio-button" type="radio" label="{`Videopuhelu" ei="" avaudu="" automaattisesti="" ilman="" kosketusta`}="" id="false" name="autoAnswer" value="{&quot;false&quot;}" checked="{autoAnswer" =="=" "false"}="" onChange="{()"> setAutoAnswer("false")}
                />
            </form.check></form.check></div>
        </form.group>
    );

    const lang = (
        <form.group className="feature_choices-form">
            <label>Telefonens språk: </label>
            <form.check style="{{" display:="" "inline-block",="" margin:="" 8,="" }}="" className="radio-button" type="radio" label="Suomi" id="suomi" name="serviceLang" value="{&quot;suomi&quot;}" checked="{serviceLang" =="=" "suomi"}="" onChange="{()"> setServiceLang("Finska")}
            />
            <form.check style="{{" display:="" "inline-block",="" margin:="" 8,="" }}="" className="radio-button" type="radio" label="Ruotsi" id="ruotsi" name="serviceLang" value="{&quot;ruotsi&quot;}" checked="{serviceLang" =="=" "ruotsi"}="" onChange="{()"> setServiceLang("svenska")}
            />
        </form.check></form.check></form.group>
    );

    const phone_options = (
        <form.group className="feature_choices-form">
            <label>Utbud av appar:</label>
            <div style="{{" marginLeft:="" 127,="" }}="">
                <form.check className="radio-button" type="radio" label="Videopuhelu" id="puhelimen1" name="option" value="{&quot;puhelimen1&quot;}" checked="{option" =="=" "puhelimen1"}="" onChange="{()"> setOption("phone1")} />
                <form.check className="radio-button" type="radio" label="Videopuhelu, puhelut, yhteystiedot, tekstiviestit, kamera, galleria" id="puhelimen2" name="option" value="{&quot;puhelimen2&quot;}" checked="{option" =="=" "puhelimen2"}="" onChange="{()"> setOption("phone2")}
                />
                <form.check className="radio-button" type="radio" label="Videopuhelu, puhelut, yhteystiedot, tekstiviestit, kamera, galleria, Google Chrome, Iltasanomat, Play -kauppa (voi lisätä omia sovelluksia)" id="puhelimen3" name="option" value="{&quot;puhelimen3&quot;}" checked="{option" =="=" "puhelimen3"}="" onChange="{()"> setOption("phone3")}
                />
            </form.check></form.check></form.check></div>
        </form.group>
    );

    returnera (
        <container fluid="">
            <row className="justify-content-md-left">
                <col xs="{12}">
                    {fel && <message variant="danger">{error.message}</message>}
                    {Laddning && <loader></loader>}
                    <h1 className="member-form-header">4 Anpassa telefonens funktioner om du vill</h1>
                    {member?.id && (
                        <div className="feature_choices">
                            <div className="text-for-companies-second">
                                Vi rekommenderar att du fyller i detta avsnitt, men det är inte obligatoriskt. Standardinställningarna är finska, öppna videosamtal med ett klick genom att trycka på svara/avvisa och ett populärt urval av applikationer. Fortsätt till
                                för att välja de funktioner du vill ha eller gå vidare.
                            </div>
                            <form onSubmit="{submitHandler}">
                                {lang}
                                {auto_response}
                                {telefon_alternativ}
                            </form>

                            <button type="button" className="tallenna" onClick="{submitHandler}">
                                Klar
                            </button>
                        </div>
                    )}
                
            </row>
        </container>
    );
};
export default FeatureChoicesPhone;
