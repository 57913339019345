import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

interface ISeniorHome {
  id: Number;
  createdAt: Date;
  updatedDate: Date;
  reference: String;
  name: String;
  show: Boolean;
}

interface IProps {
  onSendInvite: (data: any) => void;
  seniorHomes?: Array<iseniorhome>;
}

const UserInviteForm = (props: IProps) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    seniorHomeIds: "",
  });
  const [error, setError] = useState<any>({});

  const checkValidation = () => {
    const { firstName, lastName, email } = formData;
    const formError: any = {};

    if (firstName === "") formError.firstName = "This is required field!";
    if (lastName === "") formError.lastName = "This is required field!";
    if (email === "") formError.email = "This is required field!";
    return formError;
  };

  const resetError = (name: string) => {
    delete error[name];
    setError(error);
  };

  const handleChange = (e: React.ChangeEvent<htmlinputelement>) => {
    const { value, name } = e.target;

    resetError(name);
    setFormData((prevValue: any) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formError = checkValidation();
    if (Object.keys(formError).length > 0) {
      setError(formError);
    } else {
      const _formData =
        formData.seniorHomeIds === ""
          ? {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
            }
          : { ...formData, seniorHomeIds: Number(formData.seniorHomeIds) };
      props.onSendInvite(_formData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        seniorHomeIds: "",
      });
    }
  };

  return (
    <form className="user-invite-form" onSubmit="{handleSubmit}">
      <form.group controlId="firstname-invite">
        <form.label>*Förnamn</form.label> {/* Förnamn */}
        <form.control type="text" placeholder="Etunimi" name="firstName" value="{formData.firstName}" onChange="{handleChange}" isInvalid="{error.firstName}"></form.control>
        <form.control.feedback type="invalid">
          {error.firstName}
        </form.control.feedback>
      </form.group>

      <form.group controlId="lastname-invite">
        <form.label>*Efternamn</form.label> {/* Efternamn */}
        <form.control type="text" placeholder="Sukunimi" name="lastName" value="{formData.lastName}" onChange="{handleChange}" isInvalid="{error.lastName}"></form.control>
        <form.control.feedback type="invalid">
          {error.lastName}
        </form.control.feedback>
      </form.group>

      <form.group controlId="email-invite">
        <form.label>*E-post</form.label> {/* E-post */}
        <form.control type="email" placeholder="Sähköposti" name="email" value="{formData.email}" onChange="{handleChange}" isInvalid="{error.email}"></form.control>
        <form.control.feedback type="invalid">
          {error.email}
        </form.control.feedback>
      </form.group>

      {Array.isArray(props?.seniorHomes) && (
        <form.group controlId="seniorHome">
          <form.label>Vårdhem</form.label>
          <form.control as="select" name="seniorHomeIds" onChange="{handleChange}" value="{String(formData.seniorHomeIds)}">
            <option value="">Välj ett vårdhem</option>
            {props?.seniorHomes.map((seniorHome) => {
              return (
                <option value="{String(seniorHome.id)}" key="{String(seniorHome.id)}">
                  {seniorHome.name}
                </option>
              );
            })}
          </form.control>
        </form.group>
      )}

      <button style="{{" float:="" "right"="" }}="" className="tallenna user-form-button" type="submit" disabled="{" formData.firstName="" !="=" ""="" &&="" formData.lastName="" formData.email="" ?="" false="" :="" true="" }="">
        Skicka en inbjudan {/* Skicka en inbjudan */}
      </button>
    </form>
  );
};

export standard UserInviteForm;
</htmlinputelement></any></iseniorhome>